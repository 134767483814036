import classNames from 'classnames'
import { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from 'react'

import useAuthenticationProvider from '~/main_app/hooks/useAuthenticationProvider'
import { useFlag } from '~/service_providers/feature_flag'

import { TopBarLanding } from '~/main_app/components/TopBarLanding/index'
import { InviteBanner } from '../InviteBanner'
import TopBar from '../TopBarDesktop'

import TopBarTalent from '~/talent-verification/components/TopBarTalent'
import classes from './styles.module.scss'

type LayoutProps = PropsWithChildren<{ useLandingNavBar: boolean, useTalentNavBar: boolean }>

export default function Layout (props: LayoutProps): ReactElement {
  const { children, useLandingNavBar, useTalentNavBar } = props
  const [showNavbar, setShowNavbar] = useState(false)
  const [userLoggedOut, setUserLoggedOut] = useState(false)

  const { loading: loadingInviteBannerCheck, refetch: refetchShowInviteBannerConfig } = useFlag('SHOW_INVITE_BANNER', false)
  const onSignIn = useCallback(async () => {
    await refetchShowInviteBannerConfig()
    setShowNavbar(true)
    setUserLoggedOut(false)
  }, [refetchShowInviteBannerConfig])

  const onSignOut = useCallback(() => {
    setShowNavbar(false)
    setUserLoggedOut(true)
  }, [])

  const onAnonymousUserUpgraded = useCallback(async () => {
    await refetchShowInviteBannerConfig()
  }, [refetchShowInviteBannerConfig])

  const { loading: loadingAuthProvider } = useAuthenticationProvider({
    onSignIn,
    onSignOut,
    onAnonymousUserUpgraded
  })

  const isLoading = loadingInviteBannerCheck || loadingAuthProvider

  const showInviteBanner = useMemo(() => {
    if (isLoading || userLoggedOut) return false

    return true
  }, [isLoading, userLoggedOut])

  return (
    <>
      {showInviteBanner && <InviteBanner bannerContainerClassName={classes.inviteBannerContainer} />}
      {showNavbar && !useTalentNavBar && (
        <div
          className={classNames(classes.topBarContainer)}
        >
          <TopBar />
        </div>
      )}
      {useLandingNavBar && <TopBarLanding />}
      {useTalentNavBar && <TopBarTalent />}
      <main
        className={classNames({
          [classes.main as string]: !useLandingNavBar
        })}
      >
        {children}
      </main>
    </>
  )
}
