import AuthenticationError from './authentication_error'

enum EmailAlreadyExistsErrorErrorCodes {
  ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS'
}

export default class EmailAlreadyExistsError extends AuthenticationError<EmailAlreadyExistsErrorErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, EmailAlreadyExistsErrorErrorCodes.ALREADY_EXISTS)
  }
}
