import { I18NProvider } from './i18n_provider'
import { TranslationOptions } from './useTranslate'

export interface II18NProvider {
  init: () => Promise<II18NProvider>
  getLanguage: () => string
  setLanguage: (language: string) => (void | Promise<void>)
  translate: (message: string, options?: TranslationOptions) => string
}

let i18nProvider: II18NProvider | undefined

export function setI18NProvider (provider: II18NProvider): void {
  i18nProvider = provider
}

export function getI18NProvider (): II18NProvider {
  if (i18nProvider === undefined) {
    throw new Error('TranslationProvider is not set.')
  }

  return i18nProvider
}

export async function initializeI18N (): Promise<void> {
  if (i18nProvider !== undefined) {
    return
  }

  const instance = new I18NProvider()

  await instance.init()

  setI18NProvider(instance)
}
