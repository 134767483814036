import classnames from 'classnames'
import { isNil } from 'lodash'
import Image from 'next/image'
import React, { ReactElement, ReactNode } from 'react'
import { Dropdown } from 'react-bootstrap'

import useTranslate from '~/service_providers/i18n/useTranslate'
import Button from '../Button'
import DropdownMenu from '../DropdownMenu'
import InternalLink from '../InternalLink'

import PrimaryChevronDown from '~/assets/icons/primary_100_chevron_down.svg'
import classes from './styles.module.scss'

interface NavigationOptionPropsWithDropdown {
  dropdownItems: ReactElement[]
  itemsAsGrid?: boolean
  optionLabel: string
  active?: boolean
  href?: never
  label?: never
  description?: never
  icon?: never
}

interface NavigationOptionPropsWithoutDropdown {
  dropdownItems?: never
  optionLabel?: never
  itemsAsGrid?: never
  active?: boolean
  href: string
  label: string
  description?: string
  icon?: ReactNode
}

type NavigationOptionProps<HasDropdown extends boolean> = {
  withDropdown?: HasDropdown
  withHoverEffect?: boolean
} & (HasDropdown extends true
  ? NavigationOptionPropsWithDropdown
  : NavigationOptionPropsWithoutDropdown)

export default function NavigationOption<HasDropdown extends boolean = false> (
  props: NavigationOptionProps<HasDropdown>
): ReactElement {
  const { translate } = useTranslate()

  const {
    withDropdown,
    withHoverEffect,
    href,
    label,
    optionLabel,
    dropdownItems,
    active,
    itemsAsGrid,
    description,
    icon
  } = props
  if (!isNil(withDropdown) && withDropdown && !isNil(dropdownItems)) {
    return (
      <div className={classnames(classes.navigationOptionContainer, { withHoverEffect, active })}>
        <div className={classes.dropdownContainer}>
          <DropdownMenu
            autoClose
            className={classes.dropdown}
            withoutItemsBackground={itemsAsGrid ?? false}
            dropDownMenuClassName={classnames(classes.dropdownContent, { [String(classes.withGrid)]: itemsAsGrid ?? false })}
            label={
              <div className={classes.dropdownToggleContainer}>
                <span className={classnames(classes.navOption)}>{optionLabel}</span>
                <Button
                  variant={['icon']}
                  aria-label={translate('Button to open dropdown on navigation option')}
                  className={classes.dropdownToggle}
                  buttoncontentclassname={classes.dropdownToggleContent}
                >
                  <Image
                    src={PrimaryChevronDown}
                    width={16}
                    height={16}
                    alt={translate('Primary chevron')}
                    className={classes.dropdownToggleIcon}
                  />
                </Button>
              </div>
            }
          >
            {dropdownItems.map((item, i) => (
              <Dropdown.Item key={i} as='div'>
                {React.cloneElement(item, { itemsAsGrid })}
              </Dropdown.Item>
            ))}
          </DropdownMenu>
        </div>
      </div>
    )
  }

  let linkComponent
  if (!isNil(description) || !isNil(icon)) {
    linkComponent = (
      <InternalLink
        className={
          classnames(
            classes.navOption,
            {
              [String(classes.gridItem)]: itemsAsGrid ?? false,
              active,
              withHoverEffect,
              enhancedLink: true
            }
          )
        }
        href={href ?? ''}
      >
        <div className={classes.enhancedNavOption}>
          <div className={classes.enhancedIcon}>{isNil(icon) ? null : icon}</div>
          <div className={classes.enhancedLabel}>{label}</div>
          <div className={classes.enhancedDescription}>{description ?? ' '}</div>
        </div>
      </InternalLink>
    )
  } else {
    linkComponent = (
      <div className={classes.navigationOptionContainer}>
        <InternalLink
          className={classnames(classes.navOption, { active, withHoverEffect })}
          href={href ?? ''}
        >
          {label}
        </InternalLink>
      </div>
    )
  }

  return linkComponent
}
