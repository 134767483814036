import Image from 'next/image'
import { ReactElement } from 'react'

import useTranslate from '~/service_providers/i18n/useTranslate'

import NubelIsologo from '~/assets/logo/nubel_isologo.svg'

import Button from '~/main_app/components/Button'
import InternalLink from '~/main_app/components/InternalLink'
import classes from './styles.module.scss'

export default function TopBarTalent (): ReactElement {
  const { translate } = useTranslate()

  return (
    <div className={classes.navBarContainer}>
      <Image src={NubelIsologo} alt={translate('nubel logo')} />
      <div className={classes.buttonsContainer}>
        <Button
          variant='ghost'
          size={['medium', 'content']}
          className={classes.navBarButton}
        >
          {translate('Submit a review')}
        </Button>
        <Button
          variant='ghost'
          as={InternalLink}
          href='/auth/login'
          isAnchor
          size={['medium', 'content']}
          className={classes.navBarButton}
        >
          {translate('Log in')}
        </Button>
        <Button
          variant='ghost'
          as={InternalLink}
          href='/try-it-now'
          isAnchor
          size={['medium', 'content']}
          className={classes.navBarButton}
        >
          {translate('Join now')}
        </Button>
      </div>
    </div>
  )
}
