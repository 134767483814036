import { ApolloProvider } from '@apollo/client'
import { SpanStatusCode, trace } from '@opentelemetry/api'
import { isNil } from 'lodash-es'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ReactElement, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ErrorBoundary } from 'react-error-boundary'
import { ToastContainer } from 'react-toastify'

import useLoadClientSideApp from '~/service_providers/loader/use_load_client_side_app'

import { IS_PRODUCTION } from '~/constants'
import Layout from '~/main_app/components/Layout'
import isCurrentPage from '~/main_app/components/TopBarNavigation/helpers/isCurrentPage'

import '~/main_app/styles/globals.scss'

function emitOpenTelemetrySpan (error: Error, info: { componentStack: string }): void {
  trace.getTracer('error-boundary').startActiveSpan('error', (span) => {
    span.setAttribute('error-stack', info.componentStack)
    span.setAttribute('error-name', error.name)
    span.setStatus({ code: SpanStatusCode.ERROR, message: error.message })
    span.end()
  })
}

function MyApp ({ Component, pageProps }: AppProps): ReactElement | null {
  const { loading, error, apolloClient } = useLoadClientSideApp(pageProps)

  useEffect(() => {
    if (error !== undefined) {
      // eslint-disable-next-line no-console
      console.error(error)
      alert('La aplicación no pudo ser inicializada. Por favor vuelva a intentar más tarde.')
    }
  }, [error])

  if (loading || !isNil(error)) return null

  const FallbackUIComponent = (): ReactElement => <div>Something went wrong.</div>

  return (
    <ErrorBoundary FallbackComponent={FallbackUIComponent} onError={emitOpenTelemetrySpan}>
      <Head>
        {/* eslint-disable-next-line @next/next/no-page-custom-font */}
        <link
          href='https://fonts.googleapis.com/css2?family=Figtree:wght@100;200;300;400;500;600;700;800;900&display=swap'
          rel='stylesheet'
        />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        <link rel='manifest' href='/site.webmanifest' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
        {IS_PRODUCTION && (
          <script type='text/javascript' id='support-chat-bubble' async defer src='//js-na1.hs-scripts.com/43924694.js' />
        )}
      </Head>
      <DndProvider backend={HTML5Backend}>
        <ApolloProvider client={apolloClient}>
          <Layout
            useTalentNavBar={isCurrentPage(['/talent-verification', '/talent-directory'])}
            useLandingNavBar={isCurrentPage(['/try-it-now', '/auth/login', '/auth/signup'], true)}
          >
            <Component {...pageProps} />
          </Layout>
          <ToastContainer
            hideProgressBar
            position='bottom-left'
            autoClose={5000}
          />
        </ApolloProvider>
      </DndProvider>
    </ErrorBoundary>
  )
}

export default MyApp
