import Image from 'next/image'
import { ReactElement, useCallback } from 'react'
import { toast } from 'react-toastify'

import TopBarDesktopMenu from '../TopBarDesktopMenu'
import TopBarDesktopNavigation from '../TopBarNavigation'

import { useCurrentTenant } from '~/main_app/hooks/useCurrentTenant'
import { useCurrentUser } from '~/main_app/hooks/useCurrentUser'
import useUpdateUser from '~/main_app/hooks/useUpdateUser'
import currentUser from '~/service_providers/graphql/queries/current_user'
import useTranslate from '~/service_providers/i18n/useTranslate'
import { useLocalStorage } from '~/service_providers/local_storage'

import { reloadPage } from '~/utils/window'

import isoLogo from '~/assets/logo/nubel_isologo.svg'

import { isEmpty } from 'lodash'
import classes from './styles.module.scss'

export default function TopBarDesktop (): ReactElement {
  const { loading: loadingUser, user } = useCurrentUser(currentUser, { returnDataOnError: true })
  const { tenantId, loading: loadingTenant } = useCurrentTenant()
  const { translate } = useTranslate()
  const [, setTenantId] = useLocalStorage('tenantId')
  const { updateUser } = useUpdateUser()

  const handleWorkspaceChange = useCallback(async (workspaceId: string) => {
    const setTenantIdResult = setTenantId(workspaceId)
    if (setTenantIdResult.isErr()) {
      toast.error(translate('An error occurred while changing workspace'))
    }

    const updateUserResult = await updateUser({ id: user?.id ?? '', defaultTenantId: workspaceId })
    if (updateUserResult.isErr()) {
      toast.error(translate('An error occurred while changing workspace'))
    }

    reloadPage()
  }, [setTenantId, translate, updateUser, user?.id])

  return (
    <div className={classes.topBarContainer}>
      <div className={classes.topBar}>
        <Image className={classes.logo} src={isoLogo} alt='Nubel logo' priority />
        {!isEmpty(user?.roles) && <TopBarDesktopNavigation roles={user?.roles ?? []} />}
        <TopBarDesktopMenu
          user={user}
          loadingUser={loadingUser}
          currentTeam={tenantId ?? user?.defaultTenantId ?? ''}
          loadingTeam={loadingTenant}
          onWorkspaceChange={handleWorkspaceChange}
        />
      </div>
    </div>
  )
}
