import { Result, err, ok } from '@whr/core_utils/result'
import UnexpectedError from '~/errors/unexpected_error'
import apolloClient from '~/service_providers/graphql/configurations/client'
import query from './query'

export interface LanguagesQueryResult {
  supportedLanguages: Array<{ id: string }>
}

export async function getLanguages (): Promise<Result<string[], UnexpectedError>> {
  try {
    const { data } = await apolloClient.query<LanguagesQueryResult>({ query })
    return ok(data.supportedLanguages.map(({ id }) => id))
  } catch (error) {
    return err(new UnexpectedError('Error while fetching languages.', error as Error))
  }
}
