import classNames from 'classnames'
import { ReactElement, ReactNode } from 'react'

import { AnyVoidFunction } from '~/core_types'
import LegacyButton from '~/main_app/components/LegacyButton'

import classes from './styles.module.scss'

interface SocialButtonProps {
  buttonText: string
  onClick: AnyVoidFunction
  isDisabled?: boolean
  iconLeft?: ReactNode
  containerClassName?: string
  buttonClassName?: string
  textClassName?: string
}

export default function SocialButton ({
  buttonText,
  onClick,
  isDisabled,
  iconLeft,
  containerClassName,
  buttonClassName,
  textClassName
}: SocialButtonProps): ReactElement {
  return (
    <div className={classNames(classes.buttonContainer, containerClassName)}>
      <LegacyButton
        type='button'
        variant='white'
        size='medium'
        iconLeft={iconLeft}
        disabled={isDisabled}
        onClick={onClick}
        className={classNames(classes.buttonStyles, buttonClassName)}
      >
        <span className={classNames(classes.buttonText, textClassName)}>
          {buttonText}
        </span>
      </LegacyButton>
    </div>
  )
}
