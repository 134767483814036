import { gql } from '@apollo/client'

export default gql`
  mutation UpgradeIndividualAccount {
    upgradeIndividualAccount {
      code
      success
      message
    }
  }
`
