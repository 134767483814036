/* eslint-disable no-console */
import { Context, context, trace } from '@opentelemetry/api'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { W3CTraceContextPropagator } from '@opentelemetry/core'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor, Span, WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { isNil } from 'lodash-es'

import { getAuthenticationProvider } from '../authentication/authentication_provider'
import { configurationService } from '../configuration_service'
import UnexpectedError from '~/errors/unexpected_error'

type ConsoleFnSignature = (message?: any, ...optionalParams: any[]) => void

let originalFunctionWasCalled = false

export function getCurrentTraceId (): string | undefined {
  const currentContext = context.active()
  const span = trace.getSpan(currentContext)

  return span?.spanContext().traceId
}

function getSpanHandlers (appName: string, spanName: string): {
  getCurrentSpan: () => Span
  endCurrentSpan: () => void
} {
  let span: Span | undefined
  let ownSpanCreated: boolean = false

  return {
    getCurrentSpan: (): Span => {
      const currentContext = context.active()
      span = trace.getSpan(currentContext) as Span | undefined

      if (isNil(span)) {
        ownSpanCreated = true
        span = trace.getTracer(appName).startSpan(spanName, undefined, currentContext) as Span
      }

      return span
    },
    endCurrentSpan: () => {
      if (ownSpanCreated) {
        span?.end()
      }
    }
  }
}

function replaceError (key: string | symbol | number, value: any): any {
  if (value instanceof Error) {
    const newValue = Object.getOwnPropertyNames(value)
      .reduce((obj, propName) => {
        // @ts-expect-error
        obj[propName] = value[propName]
        return obj
      }, { name: value.name })
    return newValue
  } else {
    return value
  }
}

function buildWithTelemetryDecorator (appName: string): (fn: ConsoleFnSignature, consoleFunctionName: string) => ConsoleFnSignature {
  return (consoleFn, consoleFunctionName) => {
    return (...params: any[]) => {
      const { getCurrentSpan, endCurrentSpan } = getSpanHandlers(appName, 'console.' + consoleFunctionName)

      if (!originalFunctionWasCalled) {
        const span = getCurrentSpan()
        span.addEvent(
          'console.' + consoleFunctionName,
          {
            params: params.map(
              param => JSON.stringify(param, replaceError)
            )
          }
        )
        endCurrentSpan()
      }

      originalFunctionWasCalled = true
      consoleFn(...params)
      originalFunctionWasCalled = false
    }
  }
}

function buildConsoleInterceptors (appName: string): void {
  const withTelemetry = buildWithTelemetryDecorator(appName)

  const logFunctionNames = new Set([
    'assert', 'clear', 'count', 'countReset', 'debug', 'dir', 'dirxml', 'error',
    'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'table', 'time',
    'timeEnd', 'timeLog', 'timeStamp', 'trace', 'warn'
  ])

  for (const functionName of logFunctionNames) {
    // @ts-expect-error
    console[functionName] = withTelemetry(console[functionName].bind(console), functionName)
  }
}

function buildOnStartSpanHandler (originalOnstart: (span: Span, parentContext: Context) => void): (span: Span, parentContext: Context) => void {
  return (span: Span, parentContext: Context) => {
    originalOnstart(span, parentContext)
    const authenticationProvider = getAuthenticationProvider()
    if (authenticationProvider.isOk()) {
      const tenantId = authenticationProvider.value.getTenantId()

      if (tenantId.isOk() && !isNil(tenantId.value)) {
        span.setAttribute(SemanticResourceAttributes.SERVICE_NAMESPACE, tenantId.value)
        span.setAttribute('tenantId', tenantId.value)
      }
    }
  }
}

function buildJsErrorInterceptor (
  appName: string
): (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => void {
  return (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
    const { getCurrentSpan, endCurrentSpan } = getSpanHandlers(appName, 'window.onerror')

    if (error !== undefined) {
      const span = getCurrentSpan()
      span.recordException(error)
      endCurrentSpan()
    }
  }
}

let setupCalled = false

export const initializeOpenTelemetry = async (): Promise<void> => {
  if (setupCalled) {
    return
  }

  setupCalled = true

  const backendBaseURL = await configurationService.get('BACKEND_BASE_URL')
  if (backendBaseURL.isErr()) { throw new UnexpectedError('BACKEND_BASE_URL is not set') }

  const appNameConfig = await configurationService.get('APP_NAME')
  if (appNameConfig.isErr()) { throw new UnexpectedError('APP_NAME is not set') }

  const appVersionConfig = await configurationService.get('APP_VERSION')
  if (appVersionConfig.isErr()) { throw new UnexpectedError('APP_VERSION is not set') }

  const backendBaseURLValue = backendBaseURL.value
  const appNameValue = appNameConfig.value
  const appVersionValue = appVersionConfig.value

  const appName = !isNil(appNameValue) ? appNameValue : 'no_name'
  const appVersion = !isNil(appVersionValue) ? appVersionValue : 'no_version'

  const exporter = new OTLPTraceExporter({
    url: !isNil(backendBaseURLValue) ? `${backendBaseURLValue}/client-traces` : undefined
  })

  const resource = Resource.default().merge(
    new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: appName,
      [SemanticResourceAttributes.SERVICE_VERSION]: appVersion
    })
  )

  const spanProcessor = new BatchSpanProcessor(exporter, {
    maxQueueSize: 1000,
    maxExportBatchSize: 100,
    scheduledDelayMillis: 500,
    exportTimeoutMillis: 30000
  })

  spanProcessor.onStart = buildOnStartSpanHandler(spanProcessor.onStart.bind(spanProcessor))

  const provider = new WebTracerProvider({ resource })
  provider.addSpanProcessor(spanProcessor)
  provider.register({
    contextManager: new ZoneContextManager(),
    propagator: new W3CTraceContextPropagator()
  })

  buildConsoleInterceptors(appName)

  let propagateTraceHeaderCorsUrls: RegExp | undefined

  if (!isNil(backendBaseURLValue)) {
    propagateTraceHeaderCorsUrls = new RegExp(`^${
      backendBaseURLValue.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
    }`, 'ig')
  }

  registerInstrumentations({
    instrumentations: [
      new FetchInstrumentation({
        propagateTraceHeaderCorsUrls
      }),
      new XMLHttpRequestInstrumentation({
        propagateTraceHeaderCorsUrls
      })
    ]
  })

  window.onerror = buildJsErrorInterceptor(appName)
}
