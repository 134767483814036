import { CommonCacheTTLMs, ConfigurationSpecification, Scope } from '@whr/configuration_service/types'

const defaultSpecOptions: ConfigurationSpecification = {
  storageProvider: 'remote',
  scope: Scope.GLOBAL,
  cacheTTLMs: CommonCacheTTLMs.TWO_MINUTES,
  isSensitive: false
}

const tenantWithCacheSpecOptions: ConfigurationSpecification = {
  ...defaultSpecOptions,
  scope: Scope.TENANT
}

const tenantNoCacheSpecOptions: ConfigurationSpecification = {
  ...tenantWithCacheSpecOptions,
  cacheTTLMs: CommonCacheTTLMs.DISABLED
}

export const specifications: Record<string, ConfigurationSpecification> = {
  FRESHBOOKS_INVOICE_CREATION_IN_PROJECT_ASSIGNMENTS_PAGE_ENABLED: defaultSpecOptions,
  DAYS_OFF_UPDATE_ENABLED: tenantWithCacheSpecOptions,
  DAYS_OFF_CURRENT_ACCOUNT_ENABLED: tenantWithCacheSpecOptions,
  COPY_DAYS_OFF_TO_SALARY_MODAL_ENABLED: tenantWithCacheSpecOptions,
  EDIT_HR_ASSIGNMENT_INIT_VALUES_SHOW_FULLTIME_AMOUNT_USING_NEW_FULLTIME_VALUES_ENABLED: tenantWithCacheSpecOptions,
  PROJECT_ASSIGNMENT_LIST_SHOW_FULLTIME_AMOUNT_USING_NEW_FULLTIME_VALUES_ENABLED: tenantWithCacheSpecOptions,
  PROJECT_ASSIGNMENTS_HISTORY_SHOW_FULLTIME_AMOUNT_USING_NEW_FULLTIME_VALUES_ENABLED: tenantWithCacheSpecOptions,
  SALARY_PAYMENTS_FULLTIME_ENABLED: tenantWithCacheSpecOptions,
  PROFITS_REPORT_SHOW_FULLTIME_AMOUNT_USING_NEW_FULLTIME_VALUES_ENABLED: tenantWithCacheSpecOptions,
  SALES_PEOPLE_COMMISSIONS_FULLTIME_ENABLED: tenantWithCacheSpecOptions,
  BUILD_BILLABLE_ENTITY_SALE_SUMMARIZER_WITH_CONVERTED_FULLTIME_RATES_ENABLED: tenantWithCacheSpecOptions,
  IS_FRESHBOOKS_AVAILABLE: tenantWithCacheSpecOptions,
  FRESHBOOKS_ACCOUNT_ID: tenantWithCacheSpecOptions,
  IS_RECRUITERS_ENABLED: tenantWithCacheSpecOptions,
  IS_ADVANCED_TALENT_SEARCH_ENABLED: tenantNoCacheSpecOptions,
  IS_WORKSPACE_SWITCHER_ENABLED: tenantNoCacheSpecOptions,
  IS_BIZNEO_CONFIGURED: tenantWithCacheSpecOptions,
  IS_INVOICING_METHOD_SELECTOR_ENABLED: defaultSpecOptions,
  IS_PAYMENT_SPLITTING_ENABLED: tenantWithCacheSpecOptions,
  IS_SIGN_UP_WITH_EMAIL_AND_PASSWORD_ENABLED: defaultSpecOptions,
  IS_SIGN_UP_WITH_GOOGLE_ENABLED: defaultSpecOptions,
  IS_TRY_IT_NOW_PAGE_DEFAULT_PAGE: defaultSpecOptions,
  IS_NAVBAR_ACTIVATED: tenantNoCacheSpecOptions,
  SHOW_INVITE_BANNER: tenantNoCacheSpecOptions,
  IS_TALENT_FORM_V2_ENABLED: tenantWithCacheSpecOptions,
  FRONTEND_BASE_URL: { ...defaultSpecOptions, cacheTTLMs: CommonCacheTTLMs.DISABLED },
  IS_ACTIVITY_TAB_ENABLED: tenantNoCacheSpecOptions,
  IS_GOOGLE_CALENDAR_INTEGRATION_ENABLED: defaultSpecOptions,
  IS_FUTURE_INVOICE_ENABLED: tenantWithCacheSpecOptions
}
