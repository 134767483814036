import { useQuery } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import isNil from 'lodash/isNil'
import { LanguagesQueryResult } from '../helpers/languages'
import query from '../helpers/query'

interface HookResult {
  setLanguage: (language: string) => Promise<void>
  currentLanguage: string
  languages: string[]
}

export function useI18N (): HookResult {
  const { i18n } = useTranslation()
  const { data, error, loading } = useQuery<LanguagesQueryResult>(query)

  const languages = useMemo((): string[] => {
    if (!loading && isNil(error) && !isNil(data)) {
      return data.supportedLanguages.map(({ id }) => id)
    }

    return []
  }, [data, error, loading])

  const setLanguage = useCallback(async (language: string) => {
    if (languages.includes(language)) {
      try {
        await i18n.changeLanguage(language)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  }, [i18n, languages])

  return {
    setLanguage,
    currentLanguage: i18n.resolvedLanguage ?? 'en',
    languages
  }
}
