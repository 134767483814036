import { ApolloQueryResult } from '@apollo/client'

import { ConfigurationNotFoundError, ConfigurationNotFoundErrorCode } from '@whr/configuration_service/error/configuration_not_found_in_storage.js'
import { ConfigurationNotSetError, ConfigurationNotSetErrorCode } from '@whr/configuration_service/error/configuration_not_set_error.js'
import { ConfigurationStorageProvider, Scope } from '@whr/configuration_service/types.js'
import { AsyncResult, err, ok } from '@whr/core_utils/result.js'

import { isNil } from 'lodash'
import apolloClient from '~/service_providers/graphql/configurations/client'
import query from './query'

interface Configuration {
  key: string
  value: string | undefined
}

interface ConfigurationInput {
  key: string
}

interface UseGetConfigurationQueryResult {
  configuration: Configuration
}

export class RemoteStorage implements ConfigurationStorageProvider {
  async get (key: string, scope: Scope, tenantId?: string): AsyncResult<string | undefined, ConfigurationNotFoundError> {
    let result: ApolloQueryResult<UseGetConfigurationQueryResult>
    try {
      result = await apolloClient.query<UseGetConfigurationQueryResult, ConfigurationInput>({
        query,
        variables: { key },
        fetchPolicy: 'no-cache'
      })
    } catch (error) {
      return err(new ConfigurationNotFoundError(ConfigurationNotFoundErrorCode.CONFIGURATION_NOT_FOUND, `Error requesting ${key}`, error))
    }

    if (isNil(result.data)) {
      return err(new ConfigurationNotFoundError(ConfigurationNotFoundErrorCode.CONFIGURATION_NOT_FOUND, `Configuration ${key} not found`))
    }

    return ok(result.data.configuration.value)
  }

  async set (key: string, scope: Scope, value: string, tenantId: string): AsyncResult<void, ConfigurationNotSetError> {
    return err(new ConfigurationNotSetError(
      ConfigurationNotSetErrorCode.CONFIGURATION_NOT_SET_ERROR,
      'Setting configuration is not supported by RemoteStorage'
    ))
  }
}
