import { useMutation } from '@apollo/client'
import { AsyncResult, err, ok } from '@whr/core_utils/result'
import { Role as RoleEnum } from '@whr/entities/authorization/types'
import { useCallback } from 'react'
import { runApolloClientMutation } from '~/utils/apollo'
import { UpdateUserError } from './errors/update_user_error'
import mutation from './mutation'

interface Role {
  id: RoleEnum
  name: string
}

interface User {
  id: string
  email?: string
  defaultTenantId: string
  roles: Role[]
  firstName?: string
  profilePictureURL?: string
  profilePictureAttachmentId?: string
}

type UpdateUser = (input: MutationInputs) => AsyncResult<User, UpdateUserError>

interface HookResult {
  updateUser: UpdateUser
}

interface MutationInputs {
  id: string
  email?: string
  defaultTenantId?: string
  roles?: RoleEnum[]
  displayName?: string
  profilePictureURL?: string
  profilePictureAttachmentId?: string | null
}

interface MutationResult {
  updateUser: {
    code: string
    message: string
    success: boolean
    user: User
  }
}

export default function useUpdateUser (
  includeProfilePicture = false
): HookResult {
  const [mutate] = useMutation<MutationResult, { input: MutationInputs, includeProfilePicture: boolean }>(mutation)

  const updateUser = useCallback<UpdateUser>(
    async (input: MutationInputs): AsyncResult<User, UpdateUserError> => {
      try {
        const { data } = await runApolloClientMutation(
          mutate,
          {
            mutation,
            variables: {
              input,
              includeProfilePicture
            }
          }
        )
        const success = data?.updateUser.success === true

        if (!success) {
          return err(new UpdateUserError('An error ocurred while trying to update the user', data?.updateUser.message))
        }

        return ok(data.updateUser.user)
      } catch (error: any) {
        return err(new UpdateUserError('An unknown error occurred while updating the user', error))
      }
    }, [includeProfilePicture, mutate]
  )

  return {
    updateUser
  }
}
