import { FormikConfig } from 'formik'
import isPromise from 'is-promise'

type FormikSubmitHandler<Values> = FormikConfig<Values>['onSubmit']

function addSetSubmittingDeveloperHelpToSubmitHandler<Values> (
  onSubmit: FormikSubmitHandler<Values>
): FormikSubmitHandler<Values> {
  return async (values, { setSubmitting, ...formik }) => {
    let setSubmittingCalled = false
    const result = onSubmit(values, {
      setSubmitting (...args) {
        setSubmittingCalled = true

        setSubmitting(...args)
      },
      ...formik
    })

    if (isPromise(result)) {
      return await result
    } else {
      setTimeout(() => {
        if (!setSubmittingCalled) {
          // eslint-disable-next-line no-console
          console.warn('setSubmitting was not called after 2500ms on a non-async submit form handler')
        }
      }, 2500)
    }
  }
}

export {
  addSetSubmittingDeveloperHelpToSubmitHandler
}
