import { EventEmitter } from '@whr/core_utils/eventEmitter'
import { MissingDependencyError } from '@whr/core_utils/missingDependencyError'
import { AsyncResult, err, ok, Result } from '@whr/core_utils/result'
import UnexpectedError from '~/errors/unexpected_error'
import BlockedPopUpsError from '~/service_providers/authentication/errors/blocked_pop_ups_error'
import CancelledPopUpError from '~/service_providers/authentication/errors/cancelled_pop_up_error'
import ExpiredActionCodeError from '~/service_providers/authentication/errors/expired_action_code_error'
import InvalidActionCodeError from '~/service_providers/authentication/errors/invalid_action_code_error'
import InvalidCredentialsError from '~/service_providers/authentication/errors/invalid_credentials_error'
import InvalidEmailError from '~/service_providers/authentication/errors/invalid_email_error'
import AccountAlreadyExistError from '~/service_providers/authentication/errors/login_not_authorized_error'
import PopUpClosedError from '~/service_providers/authentication/errors/pop_up_closed_error'
import UnknownProviderError from '~/service_providers/authentication/errors/unknown_provider_error'
import UserDisabledError from '~/service_providers/authentication/errors/user_disabled_error'
import CredentialAlreadyInUseError from './errors/credential_already_in_use_error'
import EmailAlreadyExistsError from './errors/email_already_exits'
import InvalidCredentialError from './errors/invalid_credential_error'
import InvalidVerificationCodeError from './errors/invalid_verification_code_error'
import MissingVerificationIdError from './errors/missing_verification_id_error'
import OperationNotAllowedError from './errors/operation_not_allowed_error'
import ProviderAlreadyLinkedError from './errors/provider_already_linked_error'
import UserNotFoundError from './errors/user_not_found_error'
import UserNotLoggedInError from './errors/user_not_logged_in_error'
import WeakPasswordError from './errors/weak_password_error'

export enum Provider {
  GOOGLE
}

interface OAuthCredential {
  accessToken?: string
}

export type AuthenticationProvider = EventEmitter<AuthenticationEvents> & {
  getToken: (refresh?: boolean) => AsyncResult<string | undefined, AuthenticationErrors>
  getUserId: () => AsyncResult<string | undefined, AuthenticationErrors>
  getTenantId: () => Result<string | undefined, AuthenticationErrors>
  getPersonId: () => Result<string | undefined, AuthenticationErrors>
  isLoggedIn: () => boolean
  isUserAnonymous: () => boolean
  upgradeAnonymousUserWithEmailAndPassword: (email: string, password: string) => AsyncResult<void, AuthenticationErrors>
  upgradeAnonymousUserWithGoogle: () => AsyncResult<void, AuthenticationErrors>
  signInWithGoogle: (input: { inviteId?: string, roleIds?: string[], offeringId?: string }) => AsyncResult<void, AuthenticationErrors>
  signInWithEmailAndPassword: (input: { email: string, password: string, inviteId?: string, roleIds?: string[], offeringId?: string }) => AsyncResult<void, AuthenticationErrors>
  signUpWithEmailAndPassword: (input: { email: string, password: string, inviteId?: string }) => AsyncResult<void, AuthenticationErrors>
  signUpAnonymously: () => AsyncResult<void, AuthenticationErrors>
  signOut: () => AsyncResult<void, AuthenticationErrors>
  getGoogleCredentials: () => AsyncResult<OAuthCredential, UnexpectedError | AuthenticationErrors>
  signUpUser: (input: { inviteId?: string, roleIds?: string[], offeringId?: string }) => AsyncResult<void, AuthenticationErrors>
}

export interface AuthenticationEvents {
  initialize: AuthenticationProvider
  initializeFailed: Error
  signIn: string
  signOut: undefined
  authStateChange: AuthenticationEvents['signIn'] | AuthenticationEvents['signOut']
  anonymousUserUpgraded: undefined
}

export type AuthenticationErrors = (
  AccountAlreadyExistError
  | InvalidEmailError
  | InvalidActionCodeError
  | CancelledPopUpError
  | BlockedPopUpsError
  | PopUpClosedError
  | UnknownProviderError
  | InvalidCredentialsError
  | ExpiredActionCodeError
  | UserDisabledError
  | EmailAlreadyExistsError
  | UnexpectedError
  | UserNotLoggedInError
  | CredentialAlreadyInUseError
  | OperationNotAllowedError
  | InvalidCredentialError
  | ProviderAlreadyLinkedError
  | InvalidVerificationCodeError
  | MissingVerificationIdError
  | WeakPasswordError
  | UserNotFoundError
)

let authenticationProvider: AuthenticationProvider | null = null

export function setAuthenticationProvider (provider: AuthenticationProvider): void {
  authenticationProvider = provider
}

export function getAuthenticationProvider (): Result<AuthenticationProvider, MissingDependencyError> {
  if (authenticationProvider === null) {
    return err(new MissingDependencyError('AuthenticationProvider is not set.'))
  }

  return ok(authenticationProvider)
}
