import AuthenticationError from './authentication_error'

enum ExpiredActionCodeErrorCodes {
  EXPIRED_ACTION_CODE = 'auth/expired-action-code'
}

export default class ExpiredActionCodeError extends AuthenticationError<ExpiredActionCodeErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, ExpiredActionCodeErrorCodes.EXPIRED_ACTION_CODE)
  }
}
