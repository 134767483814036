import { Result, err, ok } from '@whr/core_utils/result'
import { OnlyStringKeys } from '@whr/core_utils/typeHelpers'
import { isNil } from 'lodash'
import { LocalStorageKeyNotSetError } from './errors'

export type Key<T> = keyof T & string

export interface LocalStorageKeys {
  tenantId: string
  personId: string
  completedPresentCandidatesTour: string
  presentedCandidatesAtLeastOnce: string
}

export class LocalStorageProvider<TKeys extends OnlyStringKeys<TKeys>> {
  public get (key: Key<TKeys>): string | undefined {
    const value = localStorage.getItem(key)

    if (isNil(value)) {
      return undefined
    }

    return value
  }

  public set (key: Key<TKeys>, value: string): Result<void, LocalStorageKeyNotSetError> {
    try {
      localStorage.setItem(key, value)

      return ok()
    } catch (error) {
      return err(new LocalStorageKeyNotSetError(`Local storage key "${key}" could not be set`, error))
    }
  }

  public remove (key: Key<TKeys>): void {
    localStorage.removeItem(key)
  }

  public clearStorage (): void {
    localStorage.clear()
  }

  public has (key: Key<TKeys>): boolean {
    return !isNil(this.get(key))
  }

  public size (): number {
    return localStorage.length
  }

  public getAllKeys (): Array<Key<TKeys>> {
    return Object.keys(localStorage) as Array<Key<TKeys>>
  }

  public getAllValues (): string[] {
    return Object.values(localStorage)
  }

  public keyAtIndex (index: number): Key<TKeys> | undefined {
    const key = localStorage.key(index)

    if (isNil(key)) {
      return undefined
    }

    return key as Key<TKeys>
  }

  public valueAtIndex (index: number): string | undefined {
    const key = this.keyAtIndex(index)

    if (isNil(key)) {
      return undefined
    }

    return this.get(key)
  }
}

export const localStorageProvider = new LocalStorageProvider<LocalStorageKeys>()
