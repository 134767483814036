import { ApolloError, ApolloQueryResult, DocumentNode, OperationVariables, useQuery } from '@apollo/client'
import guards from '~/service_providers/authorization/guards'
import currentUser from '~/service_providers/graphql/queries/current_user'
import useAuthorization from './useAuthorization'

interface User {
  id: string
  email?: string
  firstName?: string
  roles: Array<{ id: string, name: string }>
  profilePictureURL?: string
  profilePictureAttachmentId?: string
  defaultTenantId?: string
  defaultRedirectUrl?: string
  teams?: Array<{ id: string, name: string }>
  hasGoogleCalendarConnected: boolean
  isRecruiter?: boolean
  isUserAnonymous: boolean
}

export interface UseCurrentUserResult<T = User> {
  user?: T
  loading: boolean
  error?: ApolloError
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<User>>
}

interface CurrentUserConfig {
  returnDataOnError?: boolean
}

// TODO: Check Logged In guard usage here (it's interfering with query)
export function useCurrentUser<T = User> (currentUserQuery?: DocumentNode, config?: CurrentUserConfig): UseCurrentUserResult<T> {
  const { isAuthorized: isLoggedIn } = useAuthorization({ validators: [guards.userLoggedIn] })
  const { loading, error, data, refetch } = useQuery(currentUserQuery ?? currentUser, {
    errorPolicy: config?.returnDataOnError === true ? 'all' : 'none',
    skip: !isLoggedIn
  })

  return {
    user: isLoggedIn ? data?.currentUser : undefined,
    loading,
    error,
    refetch
  }
}
