import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import { AppProps } from 'next/app'

import '@whr/core_utils/result'

import { getAuthenticationProvider } from '../authentication/authentication_provider'

import apolloClient from './configurations/client'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

export function initializeApollo (initialState = null): ApolloClient<NormalizedCacheObject> {
  if (!isNil(initialState)) {
    const existingCache = apolloClient.extract()

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        )
      ]
    })

    apolloClient.cache.restore(data)
  }

  return apolloClient
}

export function addApolloState (client: ApolloClient<unknown>, pageProps: AppProps['pageProps']): any {
  if (!isUndefined(pageProps.props)) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

function setupCacheClearOnSignout (client: ApolloClient<unknown>): void {
  const authProvider = getAuthenticationProvider()

  if (authProvider.isOk()) {
    authProvider.value.on('signOut', () => {
      void client.clearStore()
    })
  }
}

export function initializeApolloWithPageProps (pageProps: AppProps['pageProps']): ApolloClient<NormalizedCacheObject> {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const client = initializeApollo(state)

  setupCacheClearOnSignout(client)

  return client
}
