import AuthenticationError from './authentication_error'

enum InvalidEmailErrorCodes {
  INVALID_EMAIL = 'auth/invalid-email'
}

export default class InvalidEmailError extends AuthenticationError<InvalidEmailErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, InvalidEmailErrorCodes.INVALID_EMAIL)
  }
}
