
import { AuthorizationEntity, AuthorizationSubjectsMetadata } from '@whr/entities/authorization'
import { isFunction, isNil } from 'lodash-es'
import { useMemo } from 'react'
import useGetAuthorizationForSubject from '../useGetAuthorizationForSubject'

export interface Action<Subject extends keyof AuthorizationSubjectsMetadata> {
  action: AuthorizationSubjectsMetadata[Subject]['actions']
  condition?: () => boolean
  field?: Subject extends keyof AuthorizationSubjectsMetadata
    ? keyof AuthorizationSubjectsMetadata[Subject]['dto']
    : never
}

interface HookResult {
  loading: boolean
  error?: Error
  isAuthorized: boolean | undefined
}

export function checkIsAuthorized<Subject extends keyof AuthorizationSubjectsMetadata> (
  authorizationEntity: AuthorizationEntity<AuthorizationSubjectsMetadata[Subject]['actions'], AuthorizationSubjectsMetadata[Subject]['dto']>,
  actions: Array<Action<Subject> | AuthorizationSubjectsMetadata[Subject]['actions']>
): boolean {
  const actionsToApply = actions.filter(action => typeof action === 'string' || !isFunction(action.condition) || action.condition())

  return actionsToApply.every(a => authorizationEntity.can(typeof a === 'string' ? a : a.action, typeof a === 'string' ? undefined : a.field))
}

export default function useVerifyActionsBySubject<Subject extends keyof AuthorizationSubjectsMetadata> (
  subject: Subject,
  actions: Array<Action<Subject> | AuthorizationSubjectsMetadata[Subject]['actions']>
): HookResult {
  const { loading, error, data: authorization } = useGetAuthorizationForSubject(subject)

  const isAuthorized = useMemo(() => {
    if (isNil(authorization)) return undefined

    return checkIsAuthorized(authorization, actions)
  }, [authorization, actions])

  return {
    loading,
    error,
    isAuthorized
  }
}
