import AuthenticationError from './authentication_error'

enum LoginNotAuthorizedErrorCodes {
  NOT_AUTHORIZED = 'LOGIN_NOT_AUTHORIZED'
}

export default class LoginNotAuthorizedError extends AuthenticationError<LoginNotAuthorizedErrorCodes> {
  constructor (message: string, originalError?: any) {
    super(message, originalError, LoginNotAuthorizedErrorCodes.NOT_AUTHORIZED)
  }
}
