import { AuthorizationEntity, AuthorizationSubjectsMetadata } from '@whr/entities/authorization'
import { useEffect, useState } from 'react'
import getCurrentUserAuthorizationEntity from '~/authorization/factories/get_current_user_authorization_entity'

interface HookResult<Subject extends keyof AuthorizationSubjectsMetadata> {
  loading: boolean
  data?: AuthorizationEntity<AuthorizationSubjectsMetadata[Subject]['actions'], AuthorizationSubjectsMetadata[Subject]['dto']>
  error?: Error
}

export default function useGetAuthorizationForSubject<Subject extends keyof AuthorizationSubjectsMetadata> (subject: Subject): HookResult<Subject> {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()
  const [data, setData] = useState<AuthorizationEntity<AuthorizationSubjectsMetadata[Subject]['actions'], AuthorizationSubjectsMetadata[Subject]['dto']>>()

  useEffect(() => {
    getCurrentUserAuthorizationEntity(subject)
      .then(result => {
        if (result.isErr()) { setError(result.error) } else { setData(result.value) }
      })
      .catch(setError)
      .finally(() => { setIsLoading(false) })
  }, [subject])

  return { loading: isLoading, error, data }
}
