import { isString } from 'lodash-es'
import { ElementType } from 'react'

export default function getComponentName<ComponentType extends (ElementType | string)> (component: ComponentType): string {
  if (isString(component)) return component
  if (isString(component.displayName)) return component.displayName
  if (isString(component.name)) return component.name

  return component.toString()
}
