// @ts-nocheck TODO: after upgrade several types with formik doesn't work
import { Formik, FormikConfig, Form as FormikForm, FormikHelpers, FormikProps } from 'formik'
import { compact, isEmpty, isFunction, isObject, isUndefined, map } from 'lodash-es'
import { MutableRefObject, ReactElement, RefCallback, useRef } from 'react'

import { IS_LOCAL } from '~/constants'
import { addSetSubmittingDeveloperHelpToSubmitHandler } from './dev_helper'

export type FormikReferenceType<Values> = FormikProps<Values> | null
export interface PluginEventHandlers<Values> {
  onPreSubmit: () => void
  onBeforeSubmit: (values: Values, formikBag: FormikHelpers<Values>) => Promise<Values>
  onSuccessfulSubmit: (formikBag: FormikHelpers<Values>) => void
  onSubmitError: (error: any) => void
  onError: (error: any) => void
}
type FormPlugin<Values> = (formikRef: MutableRefObject<FormikReferenceType<Values>>) => Partial<PluginEventHandlers<Values>>

export type FormProps<Values> = Omit<FormikConfig<Values>, 'innerRef'> & Partial<PluginEventHandlers<Values>> & {
  innerRef?: MutableRefObject<FormikReferenceType<Values>> | RefCallback<FormikReferenceType<Values>>
  className?: string
  plugins?: Array<FormPlugin<Values>>
}

function Form<Values> (props: FormProps<Values>): ReactElement {
  let {
    children,
    className,
    plugins = [],
    innerRef,
    onPreSubmit,
    onBeforeSubmit,
    onSubmit,
    onSuccessfulSubmit,
    onSubmitError,
    onError,
    ...restProps
  } = props

  if (IS_LOCAL) {
    onSubmit = addSetSubmittingDeveloperHelpToSubmitHandler(onSubmit)
  }

  const formikRef = useRef<FormikReferenceType<Values>>(null)
  const pluginEventHandlers = plugins.map(plugin => plugin(formikRef))

  return (
    <Formik
      innerRef={(formik) => {
        formikRef.current = formik
        if (isFunction(innerRef)) {
          innerRef(formik)
        } else if (isObject(innerRef)) {
          innerRef.current = formik
        }
      }}
      onSubmit={async (values, formikBag) => {
        // TODO: hacer refactor - me peleé con TypeScript y no logré generalizar con los tipos
        try {
          const onPreSubmitHandlers = compact(map(pluginEventHandlers, 'onPreSubmit'))
          for (const onPreSubmit of onPreSubmitHandlers) onPreSubmit()
          onPreSubmit?.()

          try {
            const onBeforeSubmitHandlers = compact(map(pluginEventHandlers, 'onBeforeSubmit'))
            for (const onBeforeSubmit of onBeforeSubmitHandlers) {
              values = await onBeforeSubmit(values, formikBag)
            }
            if (!isUndefined(onBeforeSubmit)) {
              values = await onBeforeSubmit(values, formikBag)
            }
            await onSubmit(values, formikBag)
          } catch (err) {
            const handlers = compact(map(pluginEventHandlers, 'onSubmitError'))
            for (const handler of handlers) handler(err)
            onSubmitError?.(err)
            if (isEmpty(handlers) || isUndefined(onSubmitError)) throw err
            return undefined
          }

          const handlers = compact(map(pluginEventHandlers, 'onSuccessfulSubmit'))
          for (const handler of handlers) handler(formikBag)
          onSuccessfulSubmit?.(formikBag)
        } catch (err) {
          const handlers = compact(map(pluginEventHandlers, 'onError'))
          for (const handler of handlers) handler(err)
          onError?.(err)
          if (isEmpty(handlers) || isUndefined(onError)) throw err
        }
      }}
      {...restProps}
    >
      <FormikForm className={className}>
        {children}
      </FormikForm>
    </Formik>
  )
}

export default Form
