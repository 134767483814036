import withFormik from '~/main_app/hoc/withFormik'
import TextInput from './TextInput'
import TextInputWithPeekPassword from './TextInputWithPeekPassword'

const FormikTextInput = withFormik(TextInputWithPeekPassword)
const FormikTextInputWithoutPeekPassword = withFormik(TextInput)

export {
  TextInputWithPeekPassword as default,
  TextInput,
  FormikTextInput,
  FormikTextInputWithoutPeekPassword
}
