import BaseError from '@whr/core_utils/baseError'

enum UnexpectedErrorCodes {
  UNEXPECTED = 'unexpected-error'
}

export default class UnexpectedError extends BaseError<UnexpectedErrorCodes> {
  constructor (message: string, originalError?: Error) {
    super(UnexpectedErrorCodes.UNEXPECTED, message, originalError)
  }
}
