import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

interface HookResult {
  loading: boolean
  error?: Error
  isAuthorized: boolean
}

type Subjects = 'Person' | 'ProjectAssignment' | 'ProjectAssignment::Salary' | 'ProjectAssignment::WorkingHours' | 'ProjectAssignment::DaysOff'

export const requiredPermissions: SubjectActionsCollection<Subjects> = [
  ['Person', ['read', { action: 'read', field: 'payoutMethod' }]],
  ['ProjectAssignment', ['read']],
  ['ProjectAssignment::Salary', ['read']],
  ['ProjectAssignment::WorkingHours', ['read']],
  ['ProjectAssignment::DaysOff', ['read']]
]

export default function useCanReadPayrollReport (): HookResult {
  const { loading, error, authorizations } = useVerifyAuthorizations(requiredPermissions)

  return {
    loading,
    error,
    isAuthorized: authorizations?.isFullyAuthorized ?? false
  }
}
