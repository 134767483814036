import classNames from 'classnames'
import { isNil } from 'lodash-es'
import { ComponentPropsWithRef, ElementType, ForwardedRef, forwardRef, MutableRefObject, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { mergeRefs } from 'react-merge-refs'
import LoadingSpinner from '~/main_app/components/LoadingSpinner'
import useVariants, { combineVariants, isVariant, Variants } from '~/utils/use_variants'
import CustomTooltip, { HelpTooltip } from '../CustomTooltip'
import classes from './styles.module.scss'

export type TextInputVariantOptions = (
  'default' |
  'transparent' |
  'validated' |
  'error' |
  'loading' |
  'square-corners-right' |
  'left-decoration' |
  'required' |
  'disabled' |
  'click-and-change' |
  'gray' |
  'blue' |
  'readonly' |
  'lateral'
)

export type TextInputProps<InputComponentType extends ElementType> = (
  ComponentPropsWithRef<InputComponentType> &
  {
    as?: InputComponentType
    label?: ReactNode
    message?: ReactNode
    icon?: ReactNode
    leftIcon?: ReactNode
    iconValidated?: ReactNode
    iconError?: ReactNode
    iconLoading?: ReactNode
    actionIcon?: ReactNode
    forceShowActionIcon?: boolean
    variant?: Variants<TextInputVariantOptions>
    withoutStateIcons?: boolean
    className?: string
    autoGrowHorizontally?: boolean
    inputClassName?: string
    inputLabelClassName?: string
    leftDecoration?: ReactNode
    helpTooltip?: HelpTooltip
  })

function InnerTextInput<InputComponentType extends ElementType> (
  props: TextInputProps<InputComponentType>,
  ref: ForwardedRef<InputComponentType>
): ReactElement {
  let {
    as: InputComponent = 'input',
    label,
    message,
    icon,
    leftIcon,
    iconValidated = <div className={classes.iconValidated} />,
    iconError = <div className={classes.iconError} />,
    iconLoading = <LoadingSpinner className={classes.iconLoading} />,
    actionIcon,
    forceShowActionIcon,
    className,
    inputClassName,
    inputLabelClassName,
    variant = 'default',
    withoutStateIcons,
    leftDecoration,
    helpTooltip,
    autoGrowHorizontally,
    ...restProps
  } = props

  const [isFocused, setIsFocused] = useState(false)
  const handleFocus = useCallback(() => { setIsFocused(true) }, [setIsFocused])
  const handleBlur = useCallback(() => { setIsFocused(false) }, [setIsFocused])
  const [isHovered, setIsHovered] = useState(false)
  const handleHover = useCallback(() => { setIsHovered(true) }, [setIsHovered])
  const handleUnhover = useCallback(() => { setIsHovered(false) }, [setIsHovered])

  const innerInputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (autoGrowHorizontally !== true || isNil(innerInputRef.current)) return

    const input = innerInputRef.current

    const handleHorizontalResize = (): void => {
      input.style.width = '0px'
      const newWidth = input.scrollWidth + 2

      input.style.width = `${newWidth}px`
    }

    input.addEventListener('input', handleHorizontalResize)
    handleHorizontalResize()

    return () => {
      input.removeEventListener('input', handleHorizontalResize)
    }
  }, [autoGrowHorizontally])

  if (!isVariant(variant, 'transparent')) {
    variant = combineVariants(variant, 'default')
  }

  if (props.disabled === true) {
    variant = combineVariants(variant, 'disabled')
  }

  if (withoutStateIcons !== true && isNil(icon)) {
    if (!isNil(iconLoading) && isVariant(variant, 'loading')) {
      icon = iconLoading
    } else if (!isNil(iconValidated) && isVariant(variant, 'validated')) {
      icon = iconValidated
    } else if (!isNil(iconError) && isVariant(variant, 'error')) {
      icon = iconError
    }
  }

  const labelClassName = classNames(
    classes.container,
    useVariants(classes, variant, { prefix: 'variant_' }),
    className
  )

  icon = useMemo(() => (
    ((forceShowActionIcon === true || isFocused || isHovered || isNil(icon)) && !isNil(actionIcon)) ? actionIcon : icon
  ), [forceShowActionIcon, isFocused, isHovered, actionIcon, icon])

  inputClassName = classNames(
    classes.input,
    { [classes.withIcon ?? '']: !isNil(icon) || !isNil(actionIcon) },
    inputClassName
  )

  const leftDecorationStyles = classNames(
    classes.leftDecoration,
    useVariants(classes, variant, { prefix: 'variant_' })
  )

  let input = (
    <InputComponent
      {...restProps}
      disabled={restProps?.disabled as boolean || isVariant(variant, 'readonly')}
      className={classNames(inputClassName, { [classes.leftpadding ?? '']: (!isNil(leftIcon)) })}
      ref={mergeRefs([ref, innerInputRef as MutableRefObject<InputComponentType | undefined>])}
    />
  )

  if (!isNil(helpTooltip)) {
    input = (
      <CustomTooltip
        placement={helpTooltip.placement}
        text={helpTooltip.text}
        variant={helpTooltip.variant}
        popperOffset={helpTooltip.popperOffset}
      >
        <InputComponent
          {...restProps}
          className={inputClassName}
          ref={mergeRefs([ref, innerInputRef as MutableRefObject<InputComponentType | undefined>])}
        />
      </CustomTooltip>
    )
  }

  return (
    <label
      className={labelClassName}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
    >
      {!isNil(label) && (
        <div className={classNames(classes.label, inputLabelClassName)}>
          {isVariant(variant, 'lateral') && leftIcon}{label}
        </div>
      )}
      <div>
        <div className={classes.inputContainer}>
          {!isNil(leftDecoration) && (
            <div className={leftDecorationStyles}>
              {leftDecoration}
            </div>
          )}
          {!isNil(leftIcon) && !isVariant(variant, 'lateral') && (
            <div className={classes.lefticonContainer}>
              {leftIcon}
            </div>
          )}
          {input}
          {!isNil(icon) && (
            <div className={classes.iconContainer}>
              {icon}
            </div>
          )}
        </div>
        {!isNil(message) && (
          <div className={classes.message}>
            {message}
          </div>
        )}
      </div>
    </label>
  )
}

const TextInput = forwardRef<any, TextInputProps<any>>(InnerTextInput)

export default TextInput
