import classNames from 'classnames'
import { isNil } from 'lodash-es'
import Image from 'next/image'
import { ReactElement } from 'react'

import DefaultAvatar from '~/assets/icons/default_avatar.svg'
import useTranslate from '~/service_providers/i18n/useTranslate'
import PlaceholderAvatar from '../PlaceholderAvatar'

import { isEmpty } from 'lodash'
import classes from './styles.module.scss'

export interface AvatarProps {
  profilePictureURL?: string
  displayName?: string
  className?: string
}

export default function Avatar (props: AvatarProps): ReactElement {
  const { translate } = useTranslate()
  const { profilePictureURL, displayName, className } = props
  const [firstName, lastName] = displayName?.split(' ') ?? []
  const container = classNames(classes.imageContainer, className)

  if (!isNil(profilePictureURL) && !isEmpty(profilePictureURL)) {
    return (
      <div className={container}>
        <Image
          src={profilePictureURL}
          width={60}
          height={60}
          alt={`${translate('Avatar of')} ${displayName ?? ''}`}
        />
      </div>
    )
  }

  if (isNil(firstName) && isNil(lastName)) {
    return (
      <div className={container}>
        <Image
          src={DefaultAvatar}
          alt={translate('default avatar')}
        />
      </div>
    )
  }

  return (
    <PlaceholderAvatar
      firstName={firstName ?? ''}
      lastName={lastName ?? ''}
      className={container}
    />
  )
}
