import classnames from 'classnames'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { useI18N } from '~/service_providers/i18n/useI18N'
import useTranslate from '~/service_providers/i18n/useTranslate'

import Button from '~/main_app/components/Button'

import classes from './styles.module.scss'

interface ComponentProps {
  className?: string
}

const availableLanguages = ['es', 'en']
export const languageNames: Record<string, { shortName: string, name: string }> = {
  es: { name: 'Español', shortName: 'ES' },
  en: { name: 'English', shortName: 'EN' }
}

export default function LanguageSwitcher (props: ComponentProps): ReactElement {
  const { className } = props
  const { translate } = useTranslate()
  const { languages, setLanguage, currentLanguage } = useI18N()

  const effectiveLanguages = useMemo(() => intersection(languages, availableLanguages), [languages])

  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(effectiveLanguages.indexOf(currentLanguage))
  const currentLanguageShort = useMemo(() => {
    return languageNames[currentLanguage]?.shortName
  }, [currentLanguage])

  const handleLanguageChange = useCallback(() => {
    const newLanguageIndex = (selectedLanguageIndex + 1) % effectiveLanguages.length
    setSelectedLanguageIndex(newLanguageIndex)
    setLanguage(effectiveLanguages[newLanguageIndex] as string).catch(() => toast.error(translate('Unable to change language')))
  }, [effectiveLanguages, selectedLanguageIndex, setLanguage, translate])

  if (isNil(effectiveLanguages) || isEmpty(effectiveLanguages)) {
    return (<></>)
  }

  return (
    <Button
      variant='secondary'
      size='medium'
      onClick={handleLanguageChange}
      className={classnames(className, classes.languageSwitcher)}
    >
      <p className={classes.languageShort}>{currentLanguageShort}</p>
    </Button>
  )
}
