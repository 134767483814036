import { isAllOk } from '@whr/core_utils/result'
import { AuthorizationEntity, AuthorizationSubjectsMetadata } from '@whr/entities/authorization'
import { useEffect, useState } from 'react'
import getCurrentUserAuthorizationEntity from '~/authorization/factories/get_current_user_authorization_entity'

export type AuthorizationEntityRecord<Subjects extends keyof AuthorizationSubjectsMetadata> = {
  [Subject in Subjects]: AuthorizationEntity<AuthorizationSubjectsMetadata[Subject]['actions'], AuthorizationSubjectsMetadata[Subject]['dto']>
}

interface GetAuthorizationForSubjectsResult<Subjects extends keyof AuthorizationSubjectsMetadata> {
  loading: boolean
  data?: AuthorizationEntityRecord<Subjects>
  error?: Error
}

export function useGetAuthorizationForSubjects<Subjects extends keyof AuthorizationSubjectsMetadata> (subjects: Subjects[], options?: { skip?: boolean }): GetAuthorizationForSubjectsResult<Subjects> {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()
  const [authorizationEntities, setAuthorizationEntities] = useState<AuthorizationEntityRecord<Subjects>>()
  const { skip = false } = options ?? { skip: false }

  useEffect(() => {
    if (skip) {
      setIsLoading(false)
      return
    }

    let alreadyGotAuthorizationEntities = false

    async function getAuthEntitiesForSubjects (): Promise<AuthorizationEntityRecord<Subjects>> {
      const authEntitiesPromises = subjects.map(async subject => await getCurrentUserAuthorizationEntity(subject))
      const authEntitiesResult = await Promise.all(authEntitiesPromises)

      if (!isAllOk(authEntitiesResult)) {
        throw new Error('An error ocurred while constructing the authorization entities', { cause: authEntitiesResult })
      }

      const authEntities = authEntitiesResult.map(r => r.value)
      const authEntitiesRecord = authEntities.reduce((acc, authEntity) => ({ ...acc, [authEntity.subjectId]: authEntity }), {})

      return authEntitiesRecord as AuthorizationEntityRecord<Subjects>
    }

    setIsLoading(true)
    getAuthEntitiesForSubjects()
      .then((res) => {
        if (!alreadyGotAuthorizationEntities) setAuthorizationEntities(res)
      })
      .catch((error) => {
        if (!alreadyGotAuthorizationEntities) setError(error)
      })
      .finally(() => {
        if (!alreadyGotAuthorizationEntities) setIsLoading(false)
      })

    return () => {
      alreadyGotAuthorizationEntities = true
    }
  }, [skip, subjects])

  return { loading: isLoading, error, data: authorizationEntities }
}
