import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import Image from 'next/image'
import { ReactElement, useCallback } from 'react'

import { useFlag } from '~/service_providers/feature_flag'
import useTranslate from '~/service_providers/i18n/useTranslate'

import { AuthorizationSubjectsMetadata } from '@whr/entities/authorization'
import { TranslationNamespace } from '@whr/entities/internationalization'
import { requiredPermissions as manageAgenciesRequiredPermissions } from '~/abms/pages/RecruitingAgencies/permissions'
import { requiredPermissions as atsListRequiredPermissions } from '~/ats/pages/OfferingsList/permissions'
import { SubjectActionsCollection, isActionObject, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'
import { requiredPermissions as billableEntitiesListRequiredPermissions } from '~/billing/authorization/useCanReadBillableEntitiesList'
import { requiredPermissions as clientListRequiredPermissions } from '~/clients/authorization/useClientAuthorization'
import { requiredPermissions as salesCommissionsListRequiredPermissions } from '~/commissions/authorization/useCanReadSalesPeople'
import { requiredPermissions as daysOffListRequiredPermissions } from '~/days_off_report/pages/DaysOffReport/permissions'
import { requiredPermissions as payrollReportRequiredPermissions } from '~/payroll-report/authorization/useCanReadPayrollReport'
import { requiredPermissions as profitAndLossRequiredPermissions } from '~/profit_and_loss/company/hooks/useCompanyProfitAndLossAuthorization'
import { requiredPermissions as projectAssignmentListRequiredPermissions } from '~/projects_assignments/authorization/useCanReadProjectAssignmentList'
import { requiredPermissions as recruitingAgenciesListRequiredPermissions } from '~/recruiting_agencies/authorization/useViewRecruitingAgenciesPage'
import { requiredPermissions as advancedTalentSearchRequiredPermissions } from '~/talent_pool/pages/AdvancedTalentSearch/permissions'
import { requiredPermissions as talentPoolListRequiredPermissions } from '~/talent_pool/pages/TalentPoolList/permissions'
import NavigationOption from '../NavigationOption'
import isCurrentPage from './helpers/isCurrentPage'

import DoubleFiles from '~/assets/icons/nav_items/double_file.svg'
import FinanceChartIcon from '~/assets/icons/nav_items/finance_chart.svg'
import TwoSmallPersonsIcon from '~/assets/icons/nav_items/two_small_persons.svg'
import VerticalBarsIcon from '~/assets/icons/vertical_bars.svg'

import { Role } from '@whr/entities/authorization/types'
import classes from './styles.module.scss'

type MenuGroupName = 'operations' | 'people' | 'sales' | 'finances'
type Permissions = SubjectActionsCollection<keyof AuthorizationSubjectsMetadata>

interface MenuItemPermission {
  item: string
  permissions: Permissions
}

interface MenuGroupPermission {
  group: MenuGroupName
  permissions: MenuItemPermission[]
}

const navBarPermissions: Permissions = [
  ...projectAssignmentListRequiredPermissions,
  ...recruitingAgenciesListRequiredPermissions,
  ...payrollReportRequiredPermissions,
  ...salesCommissionsListRequiredPermissions,
  ...billableEntitiesListRequiredPermissions,
  ...clientListRequiredPermissions,
  ...profitAndLossRequiredPermissions,
  ...daysOffListRequiredPermissions,
  ...atsListRequiredPermissions,
  ...talentPoolListRequiredPermissions,
  ...advancedTalentSearchRequiredPermissions,
  ...manageAgenciesRequiredPermissions
]

const navigationGroupAndItemsPermissions: MenuGroupPermission[] = [
  {
    group: 'operations',
    permissions: [
      {
        item: 'project-assignments',
        permissions: projectAssignmentListRequiredPermissions
      },
      {
        item: 'recruiting-agencies-list',
        permissions: recruitingAgenciesListRequiredPermissions
      },
      {
        item: 'recruiting-agencies-manage',
        permissions: manageAgenciesRequiredPermissions
      }
    ]
  },
  {
    group: 'people',
    permissions: [
      {
        item: 'payroll',
        permissions: payrollReportRequiredPermissions
      },
      {
        item: 'days-off',
        permissions: daysOffListRequiredPermissions
      },
      {
        item: 'talent-pool',
        permissions: talentPoolListRequiredPermissions
      },
      {
        item: 'ats',
        permissions: atsListRequiredPermissions
      },
      {
        item: 'advanced-search',
        permissions: advancedTalentSearchRequiredPermissions
      }
    ]
  },
  {
    group: 'sales',
    permissions: [
      {
        item: 'clients',
        permissions: clientListRequiredPermissions
      },
      {
        item: 'sales-commissions',
        permissions: salesCommissionsListRequiredPermissions
      }
    ]
  },
  {
    group: 'finances',
    permissions: [
      {
        item: 'invoicing',
        permissions: billableEntitiesListRequiredPermissions
      },
      {
        item: 'company-profit-and-loss',
        permissions: profitAndLossRequiredPermissions
      }
    ]
  }
]

export default function TopBarDesktopNavigation ({ roles }: { roles: Array<{ id: string, name: string }> }): ReactElement {
  const { translate } = useTranslate()
  const { loading: loadingFlag, value: IS_ADVANCED_TALENT_SEARCH_ENABLED } = useFlag('IS_ADVANCED_TALENT_SEARCH_ENABLED', true)
  const { loading: loadingAuthorizations, error: errorAuthorizations, authorizations } = useVerifyAuthorizations(navBarPermissions)

  const hasCandidateAssessmentRole = !isNil(roles.find(role => role.id === Role.CANDIDATE_ASSESMENT))

  const isGroupItemAuthorized = useCallback((group: string, item: string): boolean => {
    if (loadingAuthorizations || (errorAuthorizations != null) || isEmpty(authorizations)) {
      return false
    }

    const groupItemSubjectActionsSet = navigationGroupAndItemsPermissions.find((navigationGroupPermission) => navigationGroupPermission.group === group)?.permissions.find((navigationGroupPermission) => navigationGroupPermission.item === item)?.permissions
    if (isNil(groupItemSubjectActionsSet) || isEmpty(groupItemSubjectActionsSet)) {
      return false
    }

    return groupItemSubjectActionsSet.every(([subject, actionsOrActionObjectsCollection]) => {
      return actionsOrActionObjectsCollection.every((actionOrActionObject) => {
        if (isActionObject(actionOrActionObject)) {
          if (!isNil(actionOrActionObject.field)) {
            // @ts-expect-error
            return authorizations[subject]?.fieldAuthorizations?.[actionOrActionObject.field]?.can[actionOrActionObject.action] ?? false
          }
          // @ts-expect-error
          return authorizations[subject]?.can[actionOrActionObject.action] ?? false
        }
        // @ts-expect-error
        return authorizations[subject]?.can[actionOrActionObject] ?? false
      })
    })
  }, [authorizations, loadingAuthorizations, errorAuthorizations])

  const isGroupVisible = useCallback((group: string): boolean => {
    const groupPermissions = navigationGroupAndItemsPermissions.find((navigationGroupPermission) => navigationGroupPermission.group === group)
    if (isNil(groupPermissions)) {
      return false
    }

    return groupPermissions.permissions.some((groupPermission) => {
      return isGroupItemAuthorized(group, groupPermission.item)
    })
  }, [isGroupItemAuthorized])

  const advancedSearchNavigationOption = (loadingFlag || !IS_ADVANCED_TALENT_SEARCH_ENABLED)
    ? null
    : (
      <NavigationOption
        key='advanced-search'
        label={translate('Advanced Talent Search')}
        href='/talent-pool/advanced-search'
        description={translate('Unlock advanced talent searching in your pool for quick candidate identification.')}
        icon={<Image width={24} height={20} src={TwoSmallPersonsIcon} alt={translate('Advanced Talent Search')} />}
      />
      )

  return (
    <div className={classes.topbarNavigation}>
      {isGroupVisible('operations') && (
        <NavigationOption
          withDropdown
          itemsAsGrid
          withHoverEffect
          optionLabel={translate('Operations')}
          active={isCurrentPage(['/project-assignment', '/recruiting-agencies'])}
          dropdownItems={[
            ...(isGroupItemAuthorized('operations', 'project-assignments')
              ? [
                <NavigationOption
                  key='project-assignments'
                  label={translate('Assignments')}
                  href='/project-assignment/list'
                  description={translate('Streamline project assignments, empowering efficient task management for team members.')}
                  icon={<Image width={24} height={20} src={TwoSmallPersonsIcon} alt={translate('Assignments')} />}
                />
                ]
              : []
            ),
            ...(isGroupItemAuthorized('operations', 'recruiting-agencies-list')
              ? [
                <NavigationOption
                  key='recruiting-agencies-list'
                  label={translate('Recruiting Commissions Report')}
                  href='/recruiting-agencies/list'
                  description={translate('Access detailed reports on recruiting fees, gaining insights into commissions owed to recruiters or agencies.')}
                  icon={<Image width={24} height={20} src={FinanceChartIcon} alt={translate('Recruiting Commissions Report')} />}
                />
                ]
              : []
            ),
            ...(isGroupItemAuthorized('operations', 'recruiting-agencies-manage')
              ? [
                <NavigationOption
                  key='recruiting-agencies-manage'
                  label={translate('Recruiting Agencies')}
                  href='/recruiting-agencies/manage'
                  description={translate('Get visibility into partnerships with recruiting agencies, enabling seamless agency management.')}
                  icon={<Image width={24} height={20} src={TwoSmallPersonsIcon} alt={translate('Recruiting Agencies')} />}
                />
                ]
              : []
            )
          ]}
        />
      )}
      {
        isGroupVisible('people') && !hasCandidateAssessmentRole && (
          <NavigationOption
            withDropdown
            withHoverEffect
            itemsAsGrid
            optionLabel={translate('People', { ns: TranslationNamespace.NAVBAR })}
            active={isCurrentPage(['/payroll-report', '/days-off', '/talent-pool'])}
            dropdownItems={[
              ...(isGroupItemAuthorized('people', 'payroll')
                ? [
                  <NavigationOption
                    key='payroll'
                    label={translate('Payroll')}
                    href='/payroll-report/list'
                    description={translate('Effortlessly oversee payroll operations with access to reports and seamless task execution.')}
                    icon={<Image width={24} height={20} src={FinanceChartIcon} alt={translate('Payroll')} />}
                  />
                  ]
                : []
              ),
              ...(isGroupItemAuthorized('people', 'days-off')
                ? [
                  <NavigationOption
                    key='days-off'
                    label={translate('Time Off Management')}
                    href='/days-off/list'
                    description={translate('Simplify time-off management for contractors with efficient tracking and request management.')}
                    icon={<Image width={24} height={20} src={TwoSmallPersonsIcon} alt={translate('Time Off Management')} />}
                  />]
                : []
              ),
              ...(isGroupItemAuthorized('people', 'talent-pool')
                ? [
                  <NavigationOption
                    key='talent-pool'
                    label={translate('Talent Pool')}
                    href='/talent-pool/list'
                    description={translate('Manage your Talents in one place')}
                    icon={<Image width={24} height={20} src={TwoSmallPersonsIcon} alt={translate('Talent Pool')} />}
                  />
                  ]
                : []
              ),
              ...(isGroupItemAuthorized('people', 'ats')
                ? [
                  <NavigationOption
                    key='ats'
                    label={translate('ATS')}
                    href='/ats/list'
                    description={translate('Manage your Offers and candidates in one place.')}
                    icon={<Image width={24} height={24} src={VerticalBarsIcon} alt='ATS' />}
                  />
                  ]
                : []
              ),
              ...(advancedSearchNavigationOption !== null
                ? isGroupItemAuthorized('people', 'advanced-search') ? [advancedSearchNavigationOption] : []
                : []
              )
            ]}
          />
        )
      }
      {
        isGroupVisible('sales') && (
          <NavigationOption
            withDropdown
            itemsAsGrid
            withHoverEffect
            optionLabel={translate('Sales')}
            active={isCurrentPage(['/clients', '/sales-commissions'])}
            dropdownItems={[
              ...(isGroupItemAuthorized('sales', 'clients')
                ? [
                  <NavigationOption
                    key='clients'
                    label={translate('Clients')}
                    href='/clients/list'
                    description={translate('Seamlessly manage client relationships, adding, editing, or removing entries as needed.')}
                    icon={<Image width={24} height={20} src={TwoSmallPersonsIcon} alt={translate('Clients')} />}
                  />]
                : []
              ),
              ...(isGroupItemAuthorized('sales', 'sales-commissions')
                ? [
                  <NavigationOption
                    key='sales-commissions'
                    label={translate('Sales Commission')}
                    href='/sales-commissions/list'
                    description={translate('Stay on top of sales commissions with transparency and control in a dedicated section.')}
                    icon={<Image width={24} height={20} src={FinanceChartIcon} alt={translate('Sales Commission')} />}
                  />
                  ]
                : []
              )
            ]}
          />
        )
      }
      {
        isGroupVisible('finances') && (
          <NavigationOption
            withDropdown
            itemsAsGrid
            withHoverEffect
            optionLabel={translate('Finances')}
            active={isCurrentPage(['/billing', '/profit-and-loss'])}
            dropdownItems={[
              ...(isGroupItemAuthorized('finances', 'invoicing')
                ? [
                  <NavigationOption
                    key='invoicing'
                    label={translate('Invoicing')}
                    href='/billing/list'
                    description={translate('Streamline invoicing for efficient management and generation of invoices.')}
                    icon={<Image width={24} height={20} src={FinanceChartIcon} alt={translate('Invoicing')} />}
                  />
                  ]
                : []
              ),
              ...(isGroupItemAuthorized('finances', 'company-profit-and-loss')
                ? [
                  <NavigationOption
                    key='company-profit-and-loss'
                    label={translate('Company Profit and Loss')}
                    href='/profit-and-loss/company'
                    description={translate("Gain insights into your company's financial performance with detailed profit and loss metrics.")}
                    icon={<Image width={24} height={20} src={FinanceChartIcon} alt={translate('Company Profit and Loss')} />}
                  />
                  ]
                : []
              )
            ]}
          />
        )
      }
      {
        hasCandidateAssessmentRole && (
          <NavigationOption
            withDropdown
            itemsAsGrid
            withHoverEffect
            optionLabel={translate('Hiring dashboard')}
            active={isCurrentPage(['/hiring-dashboard'])}
            dropdownItems={[
              ...(hasCandidateAssessmentRole
                ? [
                  <NavigationOption
                    key='offers'
                    label={translate('My offers')}
                    href='/hiring-dashboard'
                    description={translate('See your available openings and access the ones you are interested in order to assess the candidates.')}
                    icon={<Image width={24} height={20} src={DoubleFiles} alt={translate('My offers')} />}
                  />
                  ]
                : []
              )
            ]}
          />
        )
      }
    </div>
  )
}
