
import { isEmpty, isNil, mergeWith } from 'lodash-es'
import { useMemo } from 'react'

import { AuthorizationEntity, AuthorizationSubjectsMetadata } from '@whr/entities/authorization'
import getCurrentUserAuthorizationEntity from '~/authorization/factories/get_current_user_authorization_entity'
import UnexpectedError from '~/errors/unexpected_error'
import { AuthorizationEntityRecord, useGetAuthorizationForSubjects } from '../useGetAuthorizationForSubjects'
import { Action, checkIsAuthorized } from '../useVerifyActionsBySubject'

type AuthorizationsForSubjectActions<
  Subjects extends keyof AuthorizationSubjectsMetadata
> = {
  [Subject in Subjects]?: {
    can: {
      [Action in AuthorizationSubjectsMetadata[Subject]['actions']]: boolean | undefined
    }
    fieldAuthorizations?: {
      [Field in keyof AuthorizationSubjectsMetadata[Subject]['dto']]: {
        can: {
          [Action in AuthorizationSubjectsMetadata[Subject]['actions']]: boolean | undefined
        }
      }
    }
  } & {
    isFullyAuthorized: boolean | undefined
  }
}

type Authorizations<Subjects extends keyof AuthorizationSubjectsMetadata> = AuthorizationsForSubjectActions<Subjects> & {
  isFullyAuthorized: boolean | undefined
}

interface VerifyAuthorizationsHookResult<Subjects extends keyof AuthorizationSubjectsMetadata> {
  loading: boolean
  error?: Error
  authorizations?: Authorizations<Subjects>
}

export type SubjectActions<Subject extends keyof AuthorizationSubjectsMetadata> = (
  [subject: Subject, actions: Array<Action<Subject> | AuthorizationSubjectsMetadata[Subject]['actions']>]
)

export type SubjectActionsCollection<Subjects extends keyof AuthorizationSubjectsMetadata> = Array<SubjectActions<Subjects>>

export function isActionObject<Subject extends keyof AuthorizationSubjectsMetadata> (fieldAction: Action<Subject> | any): fieldAction is Action<Subject> {
  return typeof fieldAction === 'object' && !isNil(fieldAction?.action) && !isNil(fieldAction.field)
}

function buildAuthorizationObjectForSubjectActionsPair<Subject extends keyof AuthorizationSubjectsMetadata> (
  subject: Subject,
  actions: Array<Action<Subject> | AuthorizationSubjectsMetadata[Subject]['actions']>,
  authorizationEntity: AuthorizationEntity<AuthorizationSubjectsMetadata[Subject]['actions'], AuthorizationSubjectsMetadata[Subject]['dto']>
): AuthorizationsForSubjectActions<Subject> {
  const isFullyAuthorizedForSubject = checkIsAuthorized(authorizationEntity, actions)

  const actionsForSubject = actions.filter(action => typeof action === 'string' || isNil(action.field) || isEmpty(action.field))
  const actionsForSubjectFields = actions.filter(action => typeof action !== 'string' && !isNil(action.field) && !isEmpty(action.field))

  const authObject = {
    [subject]: {
      isFullyAuthorized: isFullyAuthorizedForSubject,
      can: {
        ...actionsForSubject.reduce((acc, action) => {
          const isAuthorizedForAction = isFullyAuthorizedForSubject ? true : checkIsAuthorized(authorizationEntity, [action])

          return {
            ...acc,
            [typeof action === 'string' ? action : action.action]: isAuthorizedForAction
          }
        }
        , {})
      },
      ...actionsForSubjectFields.reduce((acc, action) => {
        if (typeof action === 'string') return acc

        return {
          ...acc,
          fieldAuthorizations: {
            // @ts-expect-error
            ...acc.fieldAuthorizations,
            [action.field as string]: {
              can: {
                // @ts-expect-error
                ...acc.fieldAuthorizations?.[action.field as string]?.can,
                [action.action]: isFullyAuthorizedForSubject ? true : checkIsAuthorized(authorizationEntity, [action])
              }
            }
          }
        }
      }
      , {})
    }
  }

  return authObject as AuthorizationsForSubjectActions<Subject>
}

function groupActionsBySubject<Subjects extends keyof AuthorizationSubjectsMetadata> (
  subjectActions: ReadonlyArray<SubjectActions<Subjects>>
): Array<[Subjects, Array<Action<Subjects> | AuthorizationSubjectsMetadata[Subjects]['actions']>]> {
  const subjectActionsObjects = subjectActions.map(([subject, actions]) => ({
    [subject]: actions
  }))

  const subjectActionsGroupedBySubject = mergeWith({}, ...subjectActionsObjects, (objValue: unknown, srcValue: unknown) => {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue)
    }

    return undefined
  }) as Record<Subjects, Array<Action<Subjects> | AuthorizationSubjectsMetadata[Subjects]['actions']>>

  const actionsToCheckBySubject = Object.entries<Array<Action<Subjects> | AuthorizationSubjectsMetadata[Subjects]['actions']>>(subjectActionsGroupedBySubject)

  return actionsToCheckBySubject as Array<[Subjects, Array<Action<Subjects> | AuthorizationSubjectsMetadata[Subjects]['actions']>]>
}

function buildAuthorizationsForSubjectActions<Subjects extends keyof AuthorizationSubjectsMetadata> (
  subjectActions: ReadonlyArray<SubjectActions<Subjects>>,
  authorizationEntities: AuthorizationEntityRecord<Subjects>
): AuthorizationsForSubjectActions<Subjects> {
  const actionsToCheckBySubject = groupActionsBySubject(subjectActions)

  const authorizations = actionsToCheckBySubject.reduce((acc, [subject, actions]) => {
    const authorizationEntityForSubject = authorizationEntities[subject]

    if (isNil(authorizationEntityForSubject)) return acc

    const authorizationsForSubject = buildAuthorizationObjectForSubjectActionsPair(subject, actions, authorizationEntityForSubject)

    return { ...acc, ...authorizationsForSubject }
  }, {})

  return authorizations
}

function checkIfFullyAuthorizedForEverySubject<Subjects extends keyof AuthorizationSubjectsMetadata> (
  authorizations: AuthorizationsForSubjectActions<Subjects>
): boolean {
  return Object.values(authorizations).every(authorization => (authorization as { isFullyAuthorized: boolean }).isFullyAuthorized)
}

export function useVerifyAuthorizations<Subjects extends keyof AuthorizationSubjectsMetadata> (
  subjectActions: ReadonlyArray<SubjectActions<Subjects>>,
  options?: { skip?: boolean }
): VerifyAuthorizationsHookResult<Subjects> {
  const subjects = useMemo(() => subjectActions.map(([subject]) => subject), [subjectActions])

  const { loading, error, data: authorizationEntities } = useGetAuthorizationForSubjects(subjects, options)

  if (isNil(authorizationEntities)) return { loading, error }

  const subjectActionsAuthorizations = buildAuthorizationsForSubjectActions(subjectActions, authorizationEntities)
  const isFullyAuthorized = checkIfFullyAuthorizedForEverySubject(subjectActionsAuthorizations)

  return {
    loading,
    error,
    authorizations: {
      ...subjectActionsAuthorizations,
      isFullyAuthorized
    }
  }
}

export async function verifyAuthorizations<Subjects extends keyof AuthorizationSubjectsMetadata> (
  subjectActions: ReadonlyArray<SubjectActions<Subjects>>
): Promise<{ authorizations: Authorizations<Subjects>, error?: UnexpectedError }> {
  // @ts-expect-error
  const authorizationEntities: AuthorizationEntityRecord<Subjects> = {}

  for (const subjectAction of subjectActions) {
    const [subject] = subjectAction

    const authorizationEntity = await getCurrentUserAuthorizationEntity(subject)
    if (authorizationEntity.isErr()) return { error: authorizationEntity.error, authorizations: { isFullyAuthorized: false } }

    authorizationEntities[subject] = authorizationEntity.value
  }

  const subjectActionsAuthorizations = buildAuthorizationsForSubjectActions(subjectActions, authorizationEntities)
  const isFullyAuthorized = checkIfFullyAuthorizedForEverySubject(subjectActionsAuthorizations)

  return {
    authorizations: {
      ...subjectActionsAuthorizations,
      isFullyAuthorized
    }
  }
}
