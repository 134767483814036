import { ApolloError, ApolloQueryResult, FetchResult, MutationFunctionOptions, MutationOptions, QueryOptions } from '@apollo/client'
import { isEmpty } from 'lodash-es'

export async function runApolloMutation<TData, TVariables> (
  mutate: (options?: MutationFunctionOptions<TData, TVariables>) => Promise<FetchResult<TData>>,
  params: MutationFunctionOptions<TData, TVariables>
): Promise<FetchResult<TData>> {
  try {
    return await mutate(params)
  } catch (err: any) {
    if (!isEmpty(err.networkError.result)) {
      return err.networkError.result
    } else {
      throw err
    }
  }
}

interface ErrorCodeFromGraphQLError {
  code: string
  path: Array<string | number> | undefined
  message: string
}

export function getErrorCodesFromApolloClientError (error?: ApolloError): ErrorCodeFromGraphQLError[] {
  return error?.graphQLErrors.map(err => ({
    code: err.extensions?.exception?.code,
    message: err.message,
    path: [...(err.path ?? [])]
  })) ?? []
}

export async function runApolloClientQuery<TData, TVariables, NullableTData = TData | null> (
  query: (options: QueryOptions<TVariables, NullableTData>) => Promise<ApolloQueryResult<NullableTData>>,
  params: QueryOptions<TVariables, NullableTData>
): Promise<ApolloQueryResult<NullableTData>> {
  try {
    return await query(params)
  } catch (err: any) {
    if (!isEmpty(err.networkError.result)) {
      return err.networkError.result
    } else {
      throw err
    }
  }
}

export async function runApolloClientMutation<TData, TVariables> (
  mutate: (options: MutationOptions<TData, TVariables>) => Promise<FetchResult<TData>>,
  params: MutationOptions<TData, TVariables>
): Promise<FetchResult<TData>> {
  try {
    return await mutate(params)
  } catch (err: any) {
    if (!isEmpty(err.networkError.result)) {
      return err.networkError.result
    } else {
      throw err
    }
  }
}
