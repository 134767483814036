import gql from 'graphql-tag'

export default gql`
  query currentUser {
    currentUser {
      id
      firstName
      email
      profilePictureURL
      profilePictureAttachmentId
      defaultTenantId
      defaultRedirectUrl
      hasGoogleCalendarConnected
      isRecruiter
      roles {
        id
        name
      }
      teams {
        id
        name
      }
      isUserAnonymous
    }
  }
`
