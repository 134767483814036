/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { FieldPolicy } from '@apollo/client'

import { PaginatedData, Pagination } from '@whr/pagination/types'
import { allKeyArgsExceptPagination, commonMerge } from '.'

interface ProjectAssignment {
  id: string
}
const meta = {
  wasPagePreviouslyVisited: false,
  lastUsedVariables: undefined
}

export const projectAssignmentsFieldPolicy: FieldPolicy<PaginatedData<'assignments', ProjectAssignment>> = {
  keyArgs: allKeyArgsExceptPagination,
  merge (existing = { assignments: [], pagination: {} as Pagination }, incoming, { variables }) {
    return commonMerge('assignments', existing, incoming, variables, meta)
  }
}
