import { ApolloClient } from '@apollo/client'
import { isFunction, isNil, isUndefined } from 'lodash-es'
import { AppProps } from 'next/app'
import { useEffect, useRef, useState } from 'react'

import injectDependencies from '~/dependencies_injector'
import initializeFirebase from '~/service_providers/firebase/initialize_firebase'
import { initializeApolloWithPageProps } from '~/service_providers/graphql/initialize_apollo'
import { initializeOpenTelemetry } from '~/service_providers/open_telemetry/initialize_open_telemetry'
import { initializeI18N } from '../i18n/provider'
import { initializePdfServiceWorker } from '../pdf_service_worker'

import { IS_LOCAL } from '~/constants'

type Initializer = ((args: AppProps['pageProps']) => Promise<void>) | ((args: AppProps['pageProps']) => unknown)

const LOADERS: Array<Initializer | Record<string, Initializer>> = [
  initializeOpenTelemetry,
  initializeFirebase,
  injectDependencies,
  { apolloClient: initializeApolloWithPageProps },
  initializeI18N,
  initializePdfServiceWorker
]

async function executeLoaders (args: AppProps['pageProps']): Promise<Record<string, any>> {
  const loadersOutput: Record<string, any> = {}

  for (let loader of LOADERS) {
    let name

    if (typeof loader === 'object') {
      name = Object.keys(loader)[0]

      if (isNil(name) || !isFunction(loader[name])) {
        continue
      }

      loader = loader[name] as Initializer
    }

    const output = await loader(args)
    if (!isUndefined(name)) loadersOutput[name] = output
  }

  return loadersOutput
}

interface UseLoadClientSideAppProps {
  loading: boolean
  error: Error | null
  apolloClient: ApolloClient<any>
}

export default function useLoadClientSideApp (pageProps: AppProps['pageProps']): UseLoadClientSideAppProps {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const loadersOutput = useRef<any>()

  useEffect(() => {
    if (IS_LOCAL) {
      // eslint-disable-next-line no-console
      console.log('Executing app loaders...')
    }

    executeLoaders(pageProps)
      .then(output => { loadersOutput.current = output })
      .catch(setError)
      .finally(() => { setLoading(false) })
  }, [pageProps])

  return { loading, error, ...loadersOutput.current }
}
