import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

interface HookResult {
  loading: boolean
  error?: Error
  actions: {
    canCreateClient: boolean
    canUpdateClient: boolean
    canCreateProject: boolean
    canUpdateProject: boolean
  }
  isAuthorized: boolean
}

export const requiredPermissions: SubjectActionsCollection<'Client' | 'Project'> = [
  ['Client', ['create', 'update']],
  ['Project', ['create', 'update']]
]

export default function useClientAuthorization (): HookResult {
  const { loading, error, authorizations } = useVerifyAuthorizations(requiredPermissions)

  const actions = {
    canCreateClient: authorizations?.Client?.can?.create ?? false,
    canUpdateClient: authorizations?.Client?.can?.update ?? false,
    canCreateProject: authorizations?.Project?.can?.create ?? false,
    canUpdateProject: authorizations?.Project?.can?.update ?? false
  }

  return {
    actions,
    loading,
    error,
    isAuthorized: authorizations?.isFullyAuthorized ?? false
  }
}
