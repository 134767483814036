import { MissingDependencyError } from '@whr/core_utils/missingDependencyError'
import isNil from 'lodash/isNil'
import { useEffect, useState } from 'react'

import { AuthenticationProvider, getAuthenticationProvider } from '~/service_providers/authentication/authentication_provider'

interface UseAuthenticationProviderResult {
  loading: boolean
  error?: MissingDependencyError | Error
  authenticationProvider?: AuthenticationProvider
}

interface UseAuthenticationProviderOptions {
  skip?: boolean
  onAnonymousUserUpgraded?: () => (void | Promise<void>)
  onSignIn?: () => (void | Promise<void>)
  onSignOut?: () => (void | Promise<void>)
}

export default function useAuthenticationProvider (options?: UseAuthenticationProviderOptions): UseAuthenticationProviderResult {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<MissingDependencyError | Error | undefined>()
  const [authenticationProvider, setAuthenticationProvider] = useState<AuthenticationProvider | undefined>()

  const { skip = false, onAnonymousUserUpgraded, onSignIn, onSignOut } = options ?? {}

  useEffect(() => {
    if (isNil(onSignIn) || isNil(authenticationProvider)) return

    const unsubscribe = authenticationProvider.on('signIn', onSignIn)

    return () => {
      unsubscribe()
    }
  }, [onSignIn, authenticationProvider])

  useEffect(() => {
    if (isNil(onSignOut) || isNil(authenticationProvider)) return

    const unsubscribe = authenticationProvider.on('signOut', onSignOut)

    return () => {
      unsubscribe()
    }
  }, [onSignOut, authenticationProvider])

  useEffect(() => {
    if (isNil(onAnonymousUserUpgraded) || isNil(authenticationProvider)) return

    const unsubscribe = authenticationProvider.on('anonymousUserUpgraded', onAnonymousUserUpgraded)

    return () => {
      unsubscribe()
    }
  }, [onAnonymousUserUpgraded, authenticationProvider])

  useEffect(() => {
    if (skip) {
      setLoading(false)
      return
    }

    const res = getAuthenticationProvider()
    if (res.isErr()) {
      setError(res.error)
      setLoading(false)
      return
    }
    res.value.on('initialize', () => {
      setAuthenticationProvider(res.value)
      setLoading(false)
    })

    res.value.on('initializeFailed', (error) => {
      setError(error)
      setLoading(false)
    })
  }, [skip])

  return {
    loading,
    error,
    authenticationProvider
  }
}
