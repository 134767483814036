import { AsyncResult, err, ok } from '@whr/core_utils/result'
import { getAuthenticationProvider } from '~/service_providers/authentication/authentication_provider'
import { Validator } from '../validator'
import BaseGuard from './base_guard'

export default class UserLoggedInGuard extends BaseGuard implements Validator {
  static NAME = 'userLoggedIn'
  private _unsuscribeFromAuthStateChange: (() => void) | undefined

  constructor () {
    super()

    this.on('_listenerAdded', ({ event }) => {
      if (event !== 'change' || (this.getListenersCount(event) !== 1)) return

      const auth = getAuthenticationProvider()
      if (auth.isOk()) {
        this._unsuscribeFromAuthStateChange = auth.value.on('authStateChange', () => {
          void this.emit('change', { data: this })
        })
      }
    })

    this.on('_listenerRemoved', ({ event }) => {
      if (event !== 'change' || (this.getListenersCount(event) !== 0)) return

      this._unsuscribeFromAuthStateChange?.()
      this._unsuscribeFromAuthStateChange = undefined
    })
  }

  get name (): string {
    return UserLoggedInGuard.NAME
  }

  async validate (): AsyncResult<boolean, Error> {
    const auth = getAuthenticationProvider()

    return await new Promise((resolve) => {
      if (auth.isErr()) {
        resolve(err(auth.error))
        return
      }

      auth.value.on('initialize', () => {
        resolve(ok(auth.value.isLoggedIn()))
      })

      auth.value.on('initializeFailed', (error) => {
        resolve(err(error))
      })
    })
  }
}
