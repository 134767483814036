import gql from 'graphql-tag'

export default gql`
mutation logIn($input: LogInInput) {
  logIn(input: $input) {
    code
    message
    success
    user {
      id
      defaultTenantId
      defaultRedirectUrl
      isRecruiter
    }
  }
}
`
