import { FormikHelpers } from 'formik'
import Image from 'next/image'
import { ReactElement, useCallback } from 'react'
import { toast } from 'react-toastify'

import useTranslate from '~/service_providers/i18n/useTranslate'

import Modal from '~/main_app/components/Modal'
import SubmitButton from '~/main_app/components/SubmitButton'
import { FormikTextInput as TextInput } from '~/main_app/components/TextInput'
import SignUpForm from '../components/SignUpForm'
import { getSchema } from '../components/SignUpForm/schema'
import SocialButton from '../components/SocialButton'

import GoogleIcon from '~/assets/icons/google.svg'

import classes from './styles.module.scss'

interface SignUpModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onGoogleSignUpButtonClicked: () => Promise<void>
  onCreateAccountButtonClicked: (
    values: { email: string, password: string },
    formikHelpers: FormikHelpers<{ email: string, password: string }>
  ) => void | Promise<void>
}

export default function SignUpModal ({
  isOpen,
  onRequestClose,
  onGoogleSignUpButtonClicked,
  onCreateAccountButtonClicked
}: SignUpModalProps): ReactElement {
  const { translate } = useTranslate()

  const handleError = useCallback((err: Error & { originalError?: Error }) => {
    toast.error(err.originalError?.message ?? err.message)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      labelledBy='signUpModal'
      className={classes.modal}
      noCloseButton={false}
    >
      <section className={classes.container}>
        <div className={classes.frame}>
          <h1 className={classes.titleText}>Nubel</h1>
          <h2 className={classes.subtitle}>{translate('Sign up with')}</h2>
          <div className={classes.socialLoginContainer}>
            <SocialButton
              buttonText={translate('Google')}
              onClick={onGoogleSignUpButtonClicked}
              iconLeft={<Image src={GoogleIcon} alt={'Google\'s icon'} />}
              isDisabled={false}
              containerClassName={classes.buttonContainer}
              buttonClassName={classes.buttonStyles}
              textClassName={classes.googleButton}
            />
          </div>
          <h2 className={classes.subtitle}>{translate('Or with')}</h2>
          <SignUpForm
            validationSchema={getSchema()}
            onSubmit={onCreateAccountButtonClicked}
            onSubmitError={handleError}
            onError={handleError}
            className={classes.form}
          >
            <div className={classes.formSection}>
              <TextInput
                label={translate('Email')}
                name='email'
                className={classes.textInput}
                inputLabelClassName={classes.textInputLabel}
              />
              <TextInput
                label={translate('Password')}
                name='password'
                type='password'
                className={classes.textInput}
                inputLabelClassName={classes.textInputLabel}
              />
            </div>
            <div className={classes.formButtonContainer}>
              <SubmitButton variant='gray' className={classes.submitButton}>
                {translate('Create Account')}
              </SubmitButton>
            </div>
          </SignUpForm>
        </div>
      </section>
    </Modal>
  )
}
