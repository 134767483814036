export default function isCurrentPage (href: string | string[], strict: boolean = false): boolean {
  const currentPath = window.location.pathname

  if (Array.isArray(href)) {
    return href.some((hrefItem) => {
      if (strict) {
        return currentPath === hrefItem
      }

      return currentPath.startsWith(hrefItem)
    })
  }

  if (strict) {
    return currentPath === href
  }

  return currentPath.startsWith(href)
}
