import classNames from 'classnames'
import { isNil } from 'lodash-es'
import Image from 'next/image'
import { ComponentPropsWithRef, ReactElement, ReactNode, useState } from 'react'
import { Placement } from 'react-bootstrap/esm/types'
import OverlayTrigger, { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger'
import BTooltip from 'react-bootstrap/Tooltip'

import useVariants, { isVariant, Variants } from '~/utils/use_variants'

import classes from './styles.module.scss'

export type CustomTooltipOptions = 'default' | 'blue' | 'dark' | 'helper' | 'popup' | 'noBottomArrow'

export interface HelpTooltip {
  placement: Placement
  text: string
  variant: Variants<CustomTooltipOptions>
  popperOffset?: number[]
}

interface Style {
  maxWidth?: string
  textAlign?: 'left' | 'center' | 'right'
}

export type CustomTooltipProps = (
  {
    icon?: ComponentPropsWithRef<typeof Image>['src']
    text: string | ReactNode
    placement: Placement
    children: ReactNode
    variant?: Variants<CustomTooltipOptions>
    className?: string
    childrenContainerClassName?: string
    popperOffset?: number[]
    style?: Style
  } & Omit<OverlayTriggerProps, 'overlay' | 'target'>
)

// TODO dig deeper into Popper docs to define wether z-index is necessary or not to show
// the tooltip over any component

export default function Tooltip (
  props: CustomTooltipProps
): ReactElement {
  const {
    icon,
    text,
    placement = 'auto',
    children,
    variant = 'default',
    className,
    childrenContainerClassName,
    popperOffset,
    ...restProps
  } = props
  const [show, setShow] = useState<boolean>(false)
  const popperConfig = {
    modifiers: [
      {
        name: 'flip',
        enabled: true
      },
      {
        name: 'offset',
        options: {
          offset: !isNil(popperOffset) ? popperOffset : [0, 15]
        }
      }
    ]
  }

  const tooltipClassName = classNames(classes.tooltip, useVariants(classes, variant, { prefix: 'variant_' }), className)
  const tooltipContentClassName = classNames(classes.tooltipContent, useVariants(classes, variant, { prefix: 'variant_' }))
  const tooltipTextContainerClassName = classNames(classes.textContainer, useVariants(classes, variant, { prefix: 'variant_' }))

  return (
    <OverlayTrigger
      show={show}
      placement={placement}
      overlay={
        (props) => (
          <BTooltip
            {...props}
            style={{ ...props.style, ...restProps.style, position: 'absolute' }}
            className={tooltipClassName}
            onMouseEnter={() => { setShow(true) }}
            onMouseLeave={() => { setShow(false) }}
          >
            <div className={tooltipContentClassName}>
              {!isNil(icon) &&
                <Image
                  src={icon}
                  width='24'
                  height='24'
                  alt=''
                />}
              <div className={tooltipTextContainerClassName}>{text}</div>
            </div>
            {isVariant(variant, 'helper') && <div className={classes.helperArrow} data-popper-arrow />}
          </BTooltip>
        )
      }
      popperConfig={popperConfig}
      {...restProps}
    >
      <div
        className={childrenContainerClassName}
        onMouseEnter={() => { setShow(true) }}
        onMouseLeave={() => { setShow(false) }}
        onFocus={() => { setShow(true) }}
        onBlur={() => { setShow(false) }}
      >
        {children}
      </div>
    </OverlayTrigger>
  )
}
