// @ts-nocheck TODO: after upgrade several types with formik doesn't work
import { AnchorHTMLAttributes, DetailedHTMLProps, ReactElement, ReactNode } from 'react'
import Link from 'next/link'
import { UrlObject } from 'url'

type BaseLink = Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'children' | 'href'>

export type LinkProps = Partial<BaseLink> & {
  href: string | UrlObject
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  children: ReactNode
}

export default function InternalLink (props: LinkProps): ReactElement {
  const { href, ...rest } = props
  return (
    <Link href={href} {...rest}>
      {props.children}
    </Link>
  )
}
