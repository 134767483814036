import { gql } from '@apollo/client'

export default gql`
mutation UpdateUser($input: UpdateUserInput!, $includeProfilePicture: Boolean!) {
  updateUser(input: $input) {
    code
    message
    success
    user {
      id
      email
      firstName
      profilePictureURL @include(if: $includeProfilePicture)
      profilePictureAttachmentId @include(if: $includeProfilePicture)
      defaultTenantId
      roles {
        id
        name
      }
    }
  }
}
`
