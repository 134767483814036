import { EventEmitter } from '@whr/core_utils/eventEmitter'
import { AsyncResult } from '@whr/core_utils/result'
import { GuardEvents, Validator } from '../validator'

export default abstract class BaseGuard extends EventEmitter<GuardEvents> implements Validator {
  abstract get name (): string
  abstract validate (action?: 'view_unauthenticated_content' | 'view_authenticated_content' | undefined): AsyncResult<boolean, Error>

  override toString (): string {
    return this.name
  }
}
