import { ConfigurationService } from '@whr/configuration_service'
import { StaticStorage } from '@whr/configuration_service/storage_provider/static_storage.js'

import { ok } from '@whr/core_utils/result'
import {
  APP_NAME,
  APP_VERSION,
  BACKEND_BASE_URL,
  MAX_FILE_UPLOAD_SIZE_BYTES,
  MINIMUM_ACCEPTED_PAYONEER_PAYMENT_IN_CENTS,
  OFFLINE_PAYMENT_METHOD_ID,
  PAYONEER_PAYMENT_METHOD_ID,
  WE_DEVELOP_RECRUITING_AGENCY_ID
} from '~/constants'
import { getAuthenticationProvider } from '../authentication/authentication_provider'
import { specifications as remoteSpecifications } from './specifications/remote'
import { specifications as staticSpecifications } from './specifications/static'
import { RemoteStorage } from './storage_provider/remote_storage'

export const configurationService = new ConfigurationService(
  {
    storageProviders: {
      remote: new RemoteStorage(),
      static: new StaticStorage({
        MAX_FILE_UPLOAD_SIZE_BYTES: MAX_FILE_UPLOAD_SIZE_BYTES.toString(),
        WE_DEVELOP_RECRUITING_AGENCY_ID,
        MINIMUM_ACCEPTED_PAYONEER_PAYMENT_IN_CENTS: MINIMUM_ACCEPTED_PAYONEER_PAYMENT_IN_CENTS.toString(),
        PAYONEER_PAYMENT_METHOD_ID,
        OFFLINE_PAYMENT_METHOD_ID,
        APP_VERSION,
        APP_NAME,
        BACKEND_BASE_URL
      })
    },
    specifications: {
      ...staticSpecifications,
      ...remoteSpecifications
    },
    getTenantId: () => {
      const authProvider = getAuthenticationProvider()
      if (authProvider.isErr()) { return ok(undefined) }

      return authProvider.value.getTenantId()
    }
  }
)
