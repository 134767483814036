import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

interface HookResult {
  loading: boolean
  error?: Error
  isAuthorized: boolean
}

type Subjects = 'Person' | 'Project' | 'Client' | 'ProjectAssignment' | 'Person::InitialHire' | 'RecruitingAgency' | 'Person::InitialHire::InstallmentPayment'

export const requiredPermissions: SubjectActionsCollection<Subjects> = [
  ['Person', ['read']],
  ['Project', ['read']],
  ['Client', ['read']],
  ['ProjectAssignment', ['read']],
  ['Person::InitialHire', ['read']],
  ['RecruitingAgency', ['read']],
  ['Person::InitialHire::InstallmentPayment', ['read']]
]

export default function useCanReadProjectAssignmentList (options?: { skip?: boolean }): HookResult {
  const { loading, error, authorizations } = useVerifyAuthorizations(requiredPermissions, { skip: options?.skip })

  return {
    loading,
    error,
    isAuthorized: authorizations?.isFullyAuthorized ?? false
  }
}
