import { OperationVariables } from '@apollo/client'
import {
  AuthCredential,
  AuthErrorCodes,
  AuthProvider,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  reauthenticateWithPopup,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
  UserCredential
} from 'firebase/auth'
import { isNil, isUndefined } from 'lodash-es'

import { EventEmitter } from '@whr/core_utils/eventEmitter'
import { AsyncResult, err, ok, Result } from '@whr/core_utils/result'

import UnexpectedError from '~/errors/unexpected_error'
import {
  AuthenticationErrors,
  AuthenticationEvents,
  AuthenticationProvider
} from '~/service_providers/authentication/authentication_provider'
import BlockedPopUpsError from '~/service_providers/authentication/errors/blocked_pop_ups_error'
import CancelledPopUpError from '~/service_providers/authentication/errors/cancelled_pop_up_error'
import InvalidCredentialsError from '~/service_providers/authentication/errors/invalid_credentials_error'
import InvalidEmailError from '~/service_providers/authentication/errors/invalid_email_error'
import LoginNotAuthorizedError from '~/service_providers/authentication/errors/login_not_authorized_error'
import PopUpClosedError from '~/service_providers/authentication/errors/pop_up_closed_error'
import UnknownProviderError from '~/service_providers/authentication/errors/unknown_provider_error'
import AuthenticationError from '../authentication/errors/authentication_error'
import EmailAlreadyExistsError from '../authentication/errors/email_already_exits'
import ExpiredActionCodeError from '../authentication/errors/expired_action_code_error'
import InvalidActionCodeError from '../authentication/errors/invalid_action_code_error'
import UserDisabledError from '../authentication/errors/user_disabled_error'

import { tokenInitializedPromise } from './initialize_firebase'

import logIn from '~/service_providers/graphql/mutations/log_in'
import currentUser from '~/service_providers/graphql/queries/current_user'
import { runApolloClientMutation } from '~/utils/apollo'
import apolloClient from '../graphql/configurations/client'
import signUpAsIndividualAccount from '../graphql/mutations/sign_up_as_individual_account'

import { Nullable } from '@whr/core_utils/typeHelpers'
import CredentialAlreadyInUseError from '../authentication/errors/credential_already_in_use_error'
import InvalidCredentialError from '../authentication/errors/invalid_credential_error'
import InvalidVerificationCodeError from '../authentication/errors/invalid_verification_code_error'
import MissingVerificationIdError from '../authentication/errors/missing_verification_id_error'
import OperationNotAllowedError from '../authentication/errors/operation_not_allowed_error'
import ProviderAlreadyLinkedError from '../authentication/errors/provider_already_linked_error'
import UserNotFoundError from '../authentication/errors/user_not_found_error'
import UserNotLoggedInError from '../authentication/errors/user_not_logged_in_error'
import WeakPasswordError from '../authentication/errors/weak_password_error'
import upgradeIndividualAccount from '../graphql/mutations/upgrade_individual_account'

const googleScopes = [
  'https://www.googleapis.com/auth/spreadsheets'
]

interface OAuthCredential {
  accessToken?: string
}

interface MutationResponse {
  code: string
  message: string
  success: boolean
}

interface LoginOrSignUpResponse {
  code: string
  message: string
  success: boolean
  user?: {
    id: string
    defaultTenantId: string
    defaultRedirectUrl?: string
    isRecruiter?: boolean
  }
}

interface LogInMutationResponse {
  logIn: LoginOrSignUpResponse
}

interface SignUpAsIndividualAccountMutationResponse {
  signUpAsIndividualAccount: LoginOrSignUpResponse
}

interface SystemAnonymousUserUpgradeMutationResponse {
  upgradeIndividualAccount: MutationResponse
}

export default class FirebaseAuthenticationProvider extends EventEmitter<AuthenticationEvents> implements AuthenticationProvider {
  private _currentUserId: string | undefined = undefined
  private _initializationFinished: boolean = false
  private _initializationError: Error | undefined = undefined

  constructor () {
    super()

    this.on('_listenerAdded', ({ event, listener }) => {
      if (event === 'authStateChange' || (event === 'signIn' && (this._currentUserId !== undefined)) || (event === 'signOut' && (this._currentUserId === undefined))) {
        // @ts-expect-error
        void listener(this._currentUserId)
      } else if (event === 'initialize' && this._initializationFinished && this._initializationError === undefined) {
        void listener(this)
      } else if (event === 'initializeFailed' && this._initializationFinished && this._initializationError !== undefined) {
        void listener(this._initializationError)
      }
    })

    this.on('signIn', userId => {
      this._currentUserId = userId
      void this.emit('authStateChange', { data: userId })
    })

    this.on('signOut', () => {
      this._currentUserId = undefined
      void this.emit('authStateChange', {})
    })

    tokenInitializedPromise
      .then(async () => {
        const userId = await this.getUserId()
        if (userId.isOk()) {
          if (!isUndefined(userId.value)) {
            this._currentUserId = userId.value
            void this.emit('signIn', { data: userId.value })
          }
        }
      })
      .then(() => {
        this._initializationFinished = true
        this._initializationError = undefined
        void this.emit('initialize', { data: this })
      })
      .catch((error) => {
        this._initializationFinished = true
        this._initializationError = error
        void this.emit('initializeFailed', { data: error })
      })
  }

  getTenantId (): Result<string | undefined, AuthenticationErrors> {
    const tenantId = localStorage.getItem('tenantId')
    return ok(tenantId ?? undefined)
  }

  getPersonId (): Result<string | undefined, AuthenticationErrors> {
    const personId = localStorage.getItem('personId')
    return ok(personId ?? undefined)
  }

  async getToken (refresh?: boolean): Promise<Result<string | undefined, AuthenticationErrors>> {
    let token
    try {
      token = await getAuth().currentUser?.getIdToken(refresh)
    } catch (error: any) {
      return err(new UnexpectedError('Could not retrieve the user\'s token'))
    }

    return ok(token)
  }

  async getUserId (): Promise<Result<string | undefined, AuthenticationErrors>> {
    if (!isUndefined(this._currentUserId)) return ok(this._currentUserId)

    let idToken
    let userId: string | undefined

    try {
      idToken = await getAuth().currentUser?.getIdTokenResult()
      userId = idToken?.claims.userId as string | undefined
    } catch (error: any) {
      return err(new UnexpectedError('Could not retrieve the user\'s token'))
    }

    try {
      if (!isNil(idToken) && isNil(userId)) {
        const { data } = await apolloClient.query({
          query: currentUser
        })

        userId = data?.currentUser?.id
      }
    } catch (error: any) {
      return err(new UnexpectedError('Could not retrieve the id associated with the user\'s token'))
    }

    return ok(userId)
  }

  isLoggedIn (): boolean {
    return !isNil(this._currentUserId)
  }

  isUserAnonymous (): boolean {
    return getAuth().currentUser?.isAnonymous ?? false
  }

  async #systemUpgradeAnonymousUser (): AsyncResult<void, AuthenticationErrors> {
    try {
      const result = await runApolloClientMutation<SystemAnonymousUserUpgradeMutationResponse, OperationVariables>(
        apolloClient.mutate,
        { mutation: upgradeIndividualAccount }
      )

      if (result.data?.upgradeIndividualAccount.success !== true) {
        return err(new UnexpectedError('Failed to upgrade anonymous user on the system'))
      }
    } catch (error) {
      return err(new AuthenticationError('An unexpected error occurred during setup admin user'))
    }

    return ok()
  }

  async #linkWithCredential (user: User, credential: AuthCredential): AsyncResult<void, AuthenticationErrors> {
    try {
      await linkWithCredential(user, credential)
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  async #linkWithPopUp (user: User, provider: AuthProvider): AsyncResult<void, AuthenticationErrors> {
    try {
      await linkWithPopup(user, provider)
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  async #signInWithCredential (credential: AuthCredential): AsyncResult<void, AuthenticationErrors> {
    const auth = getAuth()

    try {
      await signInWithCredential(auth, credential)
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  async upgradeAnonymousUserWithEmailAndPassword (email: string, password: string): AsyncResult<void, AuthenticationErrors> {
    const credential = EmailAuthProvider.credential(email, password)
    const currentUser = getAuth().currentUser

    if (isNil(currentUser)) return err(new UserNotLoggedInError('Cannot upgrade anonymous user, user is not logged in'))

    const linkResult = await this.#linkWithCredential(currentUser, credential)
    if (linkResult.isErr()) return err(linkResult.error)

    const signInResult = await this.#signInWithCredential(credential)
    if (signInResult.isErr()) return err(signInResult.error)

    const systemUpgradeResult = await this.#systemUpgradeAnonymousUser()
    if (systemUpgradeResult.isErr()) return systemUpgradeResult.into()

    void this.emit('anonymousUserUpgraded', {})

    return ok()
  }

  async upgradeAnonymousUserWithGoogle (): AsyncResult<void, AuthenticationErrors> {
    const currentUser = getAuth().currentUser
    if (isNil(currentUser)) return err(new UserNotLoggedInError('Cannot upgrade anonymous user, user is not logged in'))

    const linkResult = await this.#linkWithPopUp(currentUser, new GoogleAuthProvider())
    if (linkResult.isErr()) return err(linkResult.error)

    const systemUpgradeResult = await this.#systemUpgradeAnonymousUser()
    if (systemUpgradeResult.isErr()) return systemUpgradeResult.into()

    void this.emit('anonymousUserUpgraded', {})

    return ok()
  }

  async signInWithGoogle ({
    inviteId,
    roleIds,
    offeringId
  }: {
    inviteId?: string
    roleIds?: string[]
    offeringId?: string
  }): AsyncResult<void, AuthenticationErrors> {
    const googleSignInResult = await this._signInWithPopUp(new GoogleAuthProvider())

    if (googleSignInResult.isErr()) {
      return err(googleSignInResult.error)
    }

    const systemLogInResult = await this._systemLogIn({ inviteId, roleIds, offeringId })

    if (systemLogInResult.isErr()) {
      const shouldSignOut = systemLogInResult.error.reason !== 'LOGIN_NOT_AUTHORIZED'
      if (shouldSignOut) {
        await this.#signOutFromFirebase()
      }
      return err(systemLogInResult.error)
    }

    const refreshTokenResult = await this._refreshToken()

    if (refreshTokenResult.isErr()) {
      return err(refreshTokenResult.error)
    }

    return ok()
  }

  async signInWithEmailAndPassword ({
    email,
    password,
    inviteId,
    roleIds,
    offeringId
  }: {
    email: string
    password: string
    inviteId?: string
    roleIds?: string[]
    offeringId?: string
  }): AsyncResult<void, AuthenticationErrors> {
    const signInResult = await this.#signInWithEmailAndPassword(email, password)

    if (signInResult.isErr()) {
      return err(signInResult.error)
    }

    const systemLogInResult = await this._systemLogIn({ inviteId, roleIds, offeringId })

    if (systemLogInResult.isErr()) {
      await this.#signOutFromFirebase()
      return err(systemLogInResult.error)
    }

    const refreshTokenResult = await this._refreshToken()

    if (refreshTokenResult.isErr()) {
      return err(refreshTokenResult.error)
    }

    return ok()
  }

  async signUpUser (input: { inviteId?: string, roleIds?: string[], offeringId?: string }): AsyncResult<void, AuthenticationErrors> {
    const systemSignUpResult = await this.#systemSignUp(input)

    if (systemSignUpResult.isErr()) {
      await this.#signOutFromFirebase()
      return err(systemSignUpResult.error)
    }

    const refreshTokenResult = await this._refreshToken()

    if (refreshTokenResult.isErr()) {
      return err(refreshTokenResult.error)
    }

    return ok()
  }

  async signUpAnonymously (): AsyncResult<void, AuthenticationErrors> {
    const signUpResult = await this.#signUpAnonymously()

    if (signUpResult.isErr()) { return err(signUpResult.error) }

    const systemSignUpResult = await this.#systemSignUp()

    if (systemSignUpResult.isErr()) {
      await this.#signOutFromFirebase()
      return err(systemSignUpResult.error)
    }

    const refreshTokenResult = await this._refreshToken()

    if (refreshTokenResult.isErr()) {
      return err(refreshTokenResult.error)
    }

    return ok()
  }

  async signUpWithEmailAndPassword ({
    email,
    password,
    inviteId,
    roleIds,
    offeringId
  }: {
    email: string
    password: string
    inviteId?: string
    roleIds?: string[]
    offeringId?: string
  }): AsyncResult<void, AuthenticationErrors> {
    const signUpResult = await this.#signUpWithEmailAndPassword(email, password)

    if (signUpResult.isErr()) { return err(signUpResult.error) }

    return await this.signUpUser({ inviteId, roleIds, offeringId })
  }

  async getGoogleCredentials (): Promise<Result<OAuthCredential, UnexpectedError | AuthenticationErrors>> {
    const provider = new GoogleAuthProvider()
    googleScopes.forEach(scope => provider.addScope(scope))
    const googleReauthenticationResult = await this._reauthenticateWithPopUp(provider)
    if (googleReauthenticationResult.isErr()) return err(googleReauthenticationResult)

    const OAuthCredential = GoogleAuthProvider.credentialFromResult(googleReauthenticationResult.value)
    if (isNil(OAuthCredential)) { return err(new UnexpectedError('An unexpected error ocurred while trying to get OAuth credentials from Google')) }
    return ok(OAuthCredential)
  }

  async #signInWithEmailAndPassword (email: string, password: string): AsyncResult<void, AuthenticationErrors > {
    try {
      const auth = getAuth()
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  async #signOutFromFirebase (): AsyncResult<void, AuthenticationErrors> {
    try {
      await getAuth().signOut()
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  async signOut (): AsyncResult<void, AuthenticationErrors> {
    const firebaseSignOutResult = await this.#signOutFromFirebase()
    if (firebaseSignOutResult.isErr()) return firebaseSignOutResult

    await this.emit('signOut', {})

    localStorage.removeItem('tenantId')
    localStorage.removeItem('personId')
    localStorage.removeItem('defaultRedirectUrl')

    return ok()
  }

  async #signUpWithEmailAndPassword (email: string, password: string): AsyncResult<void, AuthenticationErrors > {
    try {
      const auth = getAuth()
      await createUserWithEmailAndPassword(auth, email, password)
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  async #signUpAnonymously (): AsyncResult<void, AuthenticationErrors > {
    try {
      const auth = getAuth()
      await signInAnonymously(auth)
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  async #systemSignUp (input?: { inviteId?: string, roleIds?: string[], offeringId?: string }): AsyncResult<void, AuthenticationErrors> {
    let data: Nullable<SignUpAsIndividualAccountMutationResponse> | undefined
    try {
      const result = await runApolloClientMutation<SignUpAsIndividualAccountMutationResponse, OperationVariables>(
        apolloClient.mutate, {
          mutation: signUpAsIndividualAccount,
          variables: { input }
        }
      )
      data = result.data
    } catch (error) {
      return err(new AuthenticationError('An unexpected error occurred during setup admin user'))
    }

    return await this.#handleLoginResponse(data?.signUpAsIndividualAccount)
  }

  private async _systemLogIn (input: { inviteId?: string, roleIds?: string[], offeringId?: string }): AsyncResult<void, AuthenticationErrors> {
    let data: Nullable<LogInMutationResponse> | undefined
    try {
      const result = await runApolloClientMutation<LogInMutationResponse, OperationVariables>(apolloClient.mutate, {
        mutation: logIn,
        variables: { input }
      })
      data = result.data
    } catch (error) {
      return err(new AuthenticationError('An unexpected error occurred during login'))
    }

    return await this.#handleLoginResponse(data?.logIn)
  }

  async #handleLoginResponse (response?: Nullable<LoginOrSignUpResponse>): AsyncResult<void, AuthenticationErrors> {
    localStorage.removeItem('tenantId')
    localStorage.removeItem('personId')
    localStorage.removeItem('defaultRedirectUrl')
    const success = response?.success === true

    if (success && !isNil(response?.user)) {
      if (!isNil(response.user.defaultTenantId)) {
        localStorage.setItem('tenantId', response.user.defaultTenantId)
      }
      if (!isNil(response.user.isRecruiter) && !response.user.isRecruiter) {
        localStorage.setItem('personId', response.user.id)
      }
      if (!isNil(response.user.defaultRedirectUrl)) {
        localStorage.setItem('defaultRedirectUrl', response.user.defaultRedirectUrl)
      }

      void this.emit('signIn', { data: response.user?.id })
    }

    if (!success && response?.code === 'LOGIN_NOT_AUTHORIZED') {
      return err(new LoginNotAuthorizedError('The account which you are trying to access is not authorized to use the system.'))
    } else if (!success) {
      return err(new AuthenticationError(response?.message ?? 'Unexpected authentication error code'))
    }

    return ok()
  }

  private async _refreshToken (): AsyncResult<void, AuthenticationErrors> {
    try {
      await this.getToken(true)
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  private async _signInWithPopUp (provider: AuthProvider): AsyncResult<void, AuthenticationErrors> {
    try {
      const auth = getAuth()
      await signInWithPopup(auth, provider)
    } catch (error: any) {
      return this._handleFirebaseError(error)
    }

    return ok()
  }

  private async _reauthenticateWithPopUp (provider: AuthProvider): Promise<Result<UserCredential, AuthenticationErrors | UnexpectedError>> {
    try {
      const auth = getAuth()
      if (auth.currentUser === null) { return err(new UnexpectedError('An unexpected error ocurred while trying to reauthenticate the user')) }
      const userCredentials = await reauthenticateWithPopup(auth.currentUser, provider)
      return ok(userCredentials)
    } catch (error: any) {
      const errorHandlerResult = this._handleFirebaseError(error)
      if (errorHandlerResult.isErr()) return err(errorHandlerResult)
      return err(new UnexpectedError('An unexpected error ocurred while trying to reauthenticate the user', error))
    }
  }

  _handleFirebaseError (error: { code: string }): Result<void, AuthenticationErrors> {
    switch (error.code) {
      case AuthErrorCodes.INVALID_EMAIL:
        return err(new InvalidEmailError('Invalid email', error))
      case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
        return err(new CancelledPopUpError('Pop-up window request was cancelled', error))
      case AuthErrorCodes.POPUP_BLOCKED:
        return err(new BlockedPopUpsError('Pop-up window blocked', error))
      case AuthErrorCodes.POPUP_CLOSED_BY_USER:
        return err(new PopUpClosedError('Pop-up window closed by user', error))
      case AuthErrorCodes.INVALID_PASSWORD:
        return err(new InvalidCredentialsError('The provided password is incorrect', error))
      case AuthErrorCodes.EXPIRED_OOB_CODE:
        return err(new ExpiredActionCodeError('Action code expired', error))
      case AuthErrorCodes.INVALID_OOB_CODE:
        return err(new InvalidActionCodeError('Invalid action code', error))
      case AuthErrorCodes.USER_DISABLED:
        return err(new UserDisabledError('Disabled user', error))
      case AuthErrorCodes.EMAIL_EXISTS:
        return err(new EmailAlreadyExistsError('This email is already in use', error))
      case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
        return err(new CredentialAlreadyInUseError('The credential is already in use', error))
      case AuthErrorCodes.INVALID_IDP_RESPONSE:
        return err(new InvalidCredentialError('The credential is invalid', error))
      case AuthErrorCodes.OPERATION_NOT_ALLOWED:
        return err(new OperationNotAllowedError('Operation not allowed', error))
      case AuthErrorCodes.PROVIDER_ALREADY_LINKED:
        return err(new ProviderAlreadyLinkedError('Provider already linked', error))
      case AuthErrorCodes.INVALID_CODE:
        return err(new InvalidVerificationCodeError('Invalid verification code', error))
      case AuthErrorCodes.MISSING_SESSION_INFO:
        return err(new MissingVerificationIdError('Missing verification id', error))
      case AuthErrorCodes.WEAK_PASSWORD:
        return err(new WeakPasswordError('The provided password is weak', error))
      case AuthErrorCodes.USER_DELETED:
        return err(new UserNotFoundError('User not found', error))
      default:
        return err(new UnknownProviderError('Unknown provider error', error))
    }
  }
}
