import AuthenticationError from './authentication_error'

enum UnknownProviderErrorCodes {
  UNKNOWN_PROVIDER_ERROR = 'auth/unknown-provider-error'
}

export default class UnknownProviderError extends AuthenticationError<UnknownProviderErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, UnknownProviderErrorCodes.UNKNOWN_PROVIDER_ERROR)
  }
}
