import { gql } from '@apollo/client'

export default gql`
mutation SignUpAsIndividualAccount($input: SetupAccountInput) {
  signUpAsIndividualAccount(input: $input) {
    code
    message
    success
    user {
      id
      defaultTenantId
      defaultRedirectUrl
    }
  }
}
`
