import { ErrorMessage, FieldHookConfig, useField } from 'formik'
import { isEmpty } from 'lodash-es'
import { ComponentPropsWithoutRef, ComponentType, ElementType, ReactElement } from 'react'
import { combineVariants } from '~/utils/use_variants'

// TODO: Fix types

interface WithFormikConfig {
  withoutMessage?: boolean
  useFieldParams?: Partial<FieldHookConfig<any>>
}

export default function withFormik<WrappedComponentType extends ElementType> (
  WrappedComponent: WrappedComponentType, config?: WithFormikConfig
): ComponentType<ComponentPropsWithoutRef<WrappedComponentType>> {
  const { useFieldParams } = config ?? { }

  const ComponentWithFormik = (props: ComponentPropsWithoutRef<WrappedComponentType>): ReactElement => {
    const { withoutValidationFeedback = false, ...restProps } = props
    const [field, { error, touched }] = useField({
      name: props.name,
      value: restProps.value,
      ...useFieldParams
    })
    const extraProps: Partial<typeof props> = {}

    if (withoutValidationFeedback === false) {
      if (!isEmpty(error) && touched) {
        // @ts-expect-error
        extraProps.variant = combineVariants(props.variant, 'error')
        // @ts-expect-error
        extraProps.message = <ErrorMessage name={props.name} />
      } else if (isEmpty(error) && touched) {
        // @ts-expect-error
        extraProps.variant = combineVariants(props.variant, 'validated')
      }
    }

    return (
      // @ts-expect-error
      <WrappedComponent
        {...field}
        {...restProps}
        {...extraProps}
      />
    )
  }

  ComponentWithFormik.displayName = `withFormik(${
    // @ts-expect-error
    (WrappedComponent?.displayName as string | undefined) ?? ''
  })`

  return ComponentWithFormik
}
