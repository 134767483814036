import isUndefined from 'lodash/isUndefined'
import { useCallback, useEffect, useState } from 'react'
import { FlagsConfigurationProvider } from './providers/configuration_provider'

export interface FeatureFlags {
  FRESHBOOKS_INVOICE_CREATION_IN_PROJECT_ASSIGNMENTS_PAGE_ENABLED: boolean
  EDIT_HR_ASSIGNMENT_INIT_VALUES_SHOW_FULLTIME_AMOUNT_USING_NEW_FULLTIME_VALUES_ENABLED: boolean
  PROJECT_ASSIGNMENT_LIST_SHOW_FULLTIME_AMOUNT_USING_NEW_FULLTIME_VALUES_ENABLED: boolean
  PROJECT_ASSIGNMENTS_HISTORY_SHOW_FULLTIME_AMOUNT_USING_NEW_FULLTIME_VALUES_ENABLED: boolean
  SALARY_PAYMENTS_FULLTIME_ENABLED: boolean
  PROFITS_REPORT_SHOW_FULLTIME_AMOUNT_USING_NEW_FULLTIME_VALUES_ENABLED: boolean
  SALES_PEOPLE_COMMISSIONS_FULLTIME_ENABLED: boolean
  BUILD_BILLABLE_ENTITY_SALE_SUMMARIZER_WITH_CONVERTED_FULLTIME_RATES_ENABLED: boolean
  IS_FRESHBOOKS_AVAILABLE: boolean
  IS_RECRUITERS_ENABLED: boolean
  IS_ADVANCED_TALENT_SEARCH_ENABLED: boolean
  IS_WORKSPACE_SWITCHER_ENABLED: boolean
  IS_BIZNEO_CONFIGURED: boolean
  IS_INVOICING_METHOD_SELECTOR_ENABLED: boolean
  IS_PAYMENT_SPLITTING_ENABLED: boolean
  IS_SIGN_UP_WITH_EMAIL_AND_PASSWORD_ENABLED: boolean
  IS_SIGN_UP_WITH_GOOGLE_ENABLED: boolean
  IS_TRY_IT_NOW_PAGE_DEFAULT_PAGE: boolean
  IS_NAVBAR_ACTIVATED: boolean
  SHOW_INVITE_BANNER: boolean
  IS_TALENT_FORM_V2_ENABLED: boolean
  IS_ACTIVITY_TAB_ENABLED: boolean
  IS_GOOGLE_CALENDAR_INTEGRATION_ENABLED: boolean
  IS_FUTURE_INVOICE_ENABLED: boolean
}

interface UseFlagResults {
  refetch: () => Promise<void>
  loading: boolean
  value: boolean
}

const flagsStore = new FlagsConfigurationProvider()

export function useFlag (key: keyof FeatureFlags, defaultValue: boolean, skip?: boolean): UseFlagResults {
  const [loading, setLoading] = useState(() => {
    if (skip === true) return false

    return isUndefined(
      flagsStore.getStaticFlag(key)
    )
  })

  const [value, setValue] = useState<boolean>(() => {
    if (skip === true) return defaultValue

    return flagsStore.getStaticFlag(key) ?? defaultValue
  })

  useEffect(() => {
    if (skip === true) return

    flagsStore.fetchFlag(key, defaultValue)
      .then(setValue)
      .finally(() => {
        setLoading(false)
      })
  }, [key, defaultValue, skip])

  const refetch = useCallback(async () => {
    const flagValue = await flagsStore.fetchFlag(key, defaultValue, { fetchPolicy: 'skip-cache' })
    setValue(flagValue)
  }, [defaultValue, key])

  return { loading, value, refetch }
}
