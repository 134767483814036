import { HttpLink, split } from '@apollo/client'
import { isEmpty, isNil } from 'lodash-es'
import { BACKEND_GRAPHQL_URL, IS_LOCAL } from '~/constants'

import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'

function getHttpBackendURL (): URL {
  if (!isNil(BACKEND_GRAPHQL_URL) && !isEmpty(BACKEND_GRAPHQL_URL)) {
    return new URL(BACKEND_GRAPHQL_URL)
  }

  if (IS_LOCAL) return new URL('http://localhost:3001/graphql')

  return new URL('')
}

const httpLink = new HttpLink({
  uri: getHttpBackendURL().toString()
})

const wsLink = new GraphQLWsLink(createClient({
  url: () => {
    const url = getHttpBackendURL()
    url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:'

    return url.toString()
  }
}))

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

export default splitLink
