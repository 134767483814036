import classNames from 'classnames'
import { isUndefined } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import classes from './styles.module.scss'

interface PlaceholderAvatarProps {
  firstName?: string
  lastName?: string
  className?: string
}

const PlaceholderAvatarColors = [
  'gray',
  'yellow',
  'blue',
  'red',
  'violet'
] as const

const transformNameToColor = (firstName?: string, lastName?: string): string => {
  if (isUndefined(firstName) || isUndefined(lastName)) {
    return PlaceholderAvatarColors[0]
  }

  const hash = [...`${firstName}${lastName[0] ?? ''}`].map(r => r.charCodeAt(0)).reduce((prev, curr) => prev + curr, 0)
  return PlaceholderAvatarColors[hash % PlaceholderAvatarColors.length] as string
}

export default function PlaceholderAvatar ({ firstName, lastName, className }: PlaceholderAvatarProps): ReactElement {
  const [background, setBackground] = useState<string>(PlaceholderAvatarColors[0])

  useEffect(() => {
    setBackground(transformNameToColor(firstName, lastName))
  }, [firstName, lastName])

  const containerClassName = classNames(classes.placeholderAvatar, classes[background], className)

  return (
    <div className={containerClassName}>
      <svg viewBox='0 0 100 100'>
        <text x='29' y='60' fontSize={30}>
          {(firstName ?? '').charAt(0) + (lastName ?? '').charAt(0)}
        </text>
      </svg>
    </div>
  )
}
