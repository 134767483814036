import classnames from 'classnames'
import { isNil } from 'lodash-es'
import {
  ComponentPropsWithoutRef,
  ElementType, ForwardedRef, forwardRef, ReactElement,
  ReactNode
} from 'react'

import useVariants, {
  combineVariants,
  isVariant,
  Variants
} from '~/utils/use_variants'

import getComponentName from '~/utils/getComponentName'

import classes from './styles.module.scss'

export type ButtonVariantOptions =
  | 'primary'
  | 'disabled'
  | 'ghost'
  | 'secondary'
  | 'link'
  | 'icon'
  | 'container'
  | 'danger'
  | 'green'
  | 'pulsating'
  | 'dark-blue'

export type ButtonSizeOptions = 'small' | 'medium' | 'large' | 'content'
type ButtonSize = Variants<ButtonSizeOptions>

export type ButtonVariants = Variants<ButtonVariantOptions>
export type ButtonProps<ButtonComponentType extends ElementType> =
  ComponentPropsWithoutRef<ButtonComponentType> & {
    as?: ButtonComponentType
    variant?: ButtonVariants
    size?: ButtonSize
    isAnchor?: boolean
    type?: string
    className?: string
    children: ReactNode
    iconLeft?: ReactNode
    iconRight?: ReactNode
  }

const Button = forwardRef(ButtonInner) as <
  ButtonComponentType extends ElementType
>(
  props: ButtonProps<ButtonComponentType> & {
    ref?: ForwardedRef<ButtonComponentType>
  }
) => ReactElement

function ButtonInner<ButtonComponentType extends ElementType> (
  props: ButtonProps<ButtonComponentType>,
  ref: ForwardedRef<ButtonComponentType>
): ReactElement {
  let {
    as: ButtonComponent = 'button',
    variant = 'primary',
    size = 'small',
    isAnchor = false,
    type,
    className,
    children,
    iconLeft,
    iconRight,
    disabled,
    ...restProps
  } = props

  if (ButtonComponent === 'button') type = type ?? 'button'

  if (
    !isVariant(variant, 'secondary') &&
    !isVariant(variant, 'link') &&
    !isVariant(variant, 'icon')
  ) {
    variant = combineVariants(variant, 'primary')
  }

  if (isVariant(variant, 'disabled') && !isVariant(variant, 'secondary')) {
    variant = combineVariants(variant, 'primary')
  }

  if (!isNil(disabled) && disabled === true) {
    variant = combineVariants(variant, 'disabled')
  }

  className = classnames(
    classes.button,
    useVariants(classes, size, { prefix: 'size_' }),
    useVariants(classes, variant, { prefix: 'variant_' }),
    useVariants(classes, isAnchor ? 'a' : getComponentName(ButtonComponent), {
      prefix: 'as_',
      ignoreUndefinedVariants: true
    }),
    className
  )

  return (
    <ButtonComponent
      type={type}
      className={className}
      ref={ref}
      tabIndex={0}
      role={isAnchor || ButtonComponent === 'a' ? 'link' : 'button'}
      disabled={disabled}
      {...restProps}
    >
      {!isNil(iconLeft) && <div className={classes.iconLeft}>{iconLeft}</div>}
      <span>{children}</span>
      {!isNil(iconRight) && (
        <div className={classes.iconRight}>{iconRight}</div>
      )}
    </ButtonComponent>
  )
}

export default Button
