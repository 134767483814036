import { isNil } from 'lodash-es'
import Image from 'next/image'
import { ElementType, ReactElement, useCallback, useMemo, useState } from 'react'
import EyeIcon from '~/assets/icons/eye-black.svg'
import EyeSlashIcon from '~/assets/icons/eye-slash-black.svg'
import useTranslate from '~/service_providers/i18n/useTranslate'
import LegacyButton from '../LegacyButton'
import TextInput, { TextInputProps } from './TextInput'
import classes from './peek_password_styles.module.scss'

export default function TextInputWithPeekPassword<InputComponentType extends ElementType> (
  props: TextInputProps<InputComponentType>
): ReactElement {
  const { translate } = useTranslate()
  let { type, actionIcon, forceShowActionIcon, ...restProps } = props
  const [peekEnabled, setPeekEnabled] = useState(false)
  const togglePeekEnabled = useCallback(() => {
    setPeekEnabled(peekEnabled => !peekEnabled)
  }, [setPeekEnabled])
  const isPassword = type === 'password'

  type = useMemo(() => (
    isPassword && peekEnabled ? 'text' : type
  ), [type, isPassword, peekEnabled])

  actionIcon = useMemo(() => {
    if (!isNil(actionIcon) || !isPassword) { return actionIcon }

    return (
      <LegacyButton className={classes.button} variant='icon' onClick={togglePeekEnabled}>
        <Image
          src={peekEnabled ? EyeIcon : EyeSlashIcon}
          alt={peekEnabled ? translate('Hide password') : translate('Show password')}
          fill
          objectFit='contain'
        />
      </LegacyButton>
    )
  }, [togglePeekEnabled, actionIcon, isPassword, peekEnabled, translate])

  return (
    <TextInput
      type={type}
      actionIcon={actionIcon}
      forceShowActionIcon={peekEnabled}
      {...restProps}
    />
  )
}
