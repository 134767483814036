import Image from 'next/image'
import { ReactElement } from 'react'

import useTranslate from '~/service_providers/i18n/useTranslate'

import LanguageSwitcher from '~/main_app/components/LanguageSwitcher'
import Button from '../Button'
import InternalLink from '../InternalLink'

import ProfileIcon from '~/assets/icons/profile_picture_landing.svg'
import NubelIsologo from '~/assets/logo/nubel_isologo_white.svg'

import classes from './styles.module.scss'

export function TopBarLanding (): ReactElement {
  const { translate } = useTranslate()

  return (
    <div className={classes.navBarContainer}>
      <Button as={InternalLink} variant='icon' href='/try-it-now'>
        <Image src={NubelIsologo} alt={translate('nubel logo')} width={60} height={60} />
      </Button>
      <div className={classes.buttonsContainer}>
        <LanguageSwitcher className={classes.langSwitcher} />
        <Button
          variant='secondary'
          as={InternalLink}
          href='/auth/login'
          isAnchor
          size={['medium', 'content']}
          iconLeft={<Image src={ProfileIcon} width='16' height='16' alt={translate('Profile Icon')} />}
        >
          {translate('Log in')}
        </Button>
      </div>
    </div>
  )
}
