import isUndefined from 'lodash-es/isUndefined'
import { configurationService } from '~/service_providers/configuration_service'

const mapper = {
  true: true,
  false: false
}

interface FetchFlagOptions {
  fetchPolicy: 'cache-first' | 'skip-cache'
}

export class FlagsConfigurationProvider {
  #data: Record<string, Promise<boolean>>
  #staticData: Record<string, boolean>

  constructor () {
    this.#staticData = {}
    this.#data = {}
  }

  getStaticFlag (key: string): boolean | undefined {
    return this.#staticData[key]
  }

  async fetchFlag (key: string, defaultValue: boolean, options?: FetchFlagOptions): Promise<boolean> {
    const { fetchPolicy = 'cache-first' } = options ?? {}

    const value = this.#data[key]
    if (!isUndefined(value) && fetchPolicy === 'cache-first') {
      return await value
    }

    this.#data[key] = configurationService.get(key)
      .then(result => {
        if (result.isErr()) {
          this.#staticData[key] = defaultValue
          return defaultValue
        } else {
          const mappedValue = mapper[result.value as keyof typeof mapper]
          const effectiveValue = isUndefined(mappedValue)
            ? defaultValue
            : mappedValue

          this.#staticData[key] = effectiveValue
          return effectiveValue
        }
      })
      .catch(() => {
        this.#staticData[key] = defaultValue
        return defaultValue
      })

    // @ts-expect-error
    return await this.#data[key]
  }
}
