import { ReactElement, useCallback } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'
import Button from '~/main_app/components/Button'

import { toast } from 'react-toastify'
import LoadingSpinner from '~/main_app/components/LoadingSpinner'
import classes from './styles.module.scss'
import { useFlag } from '~/service_providers/feature_flag'

export type WorkspaceSwitchChangeHandler = ((workspaceId: string) => void) | ((workspaceId: string) => Promise<void>)

interface Workspace {
  id: string
  name: string
}

interface WorkspaceSwitcherProps {
  onChange: WorkspaceSwitchChangeHandler
  workspaces: Workspace[]
  selectedWorkspaceId: string
  loading?: boolean
  containerClassName?: string
}

export function WorkspaceSwitcher (props: WorkspaceSwitcherProps): ReactElement | null {
  const { loading: loadingFlag, value: IS_WORKSPACE_SWITCHER_ENABLED } = useFlag('IS_WORKSPACE_SWITCHER_ENABLED', true)
  const { onChange, workspaces, selectedWorkspaceId, containerClassName, loading = false } = props

  const currentWorkspaceName = workspaces.find(workspace => workspace.id === selectedWorkspaceId)?.name

  const handleWorkspaceChange = useCallback(async (workspaceId: string) => {
    try {
      await onChange(workspaceId)
    } catch (error) {
      toast.error('An error occurred while changing workspace')
    }
  }, [onChange])

  if (loadingFlag || !IS_WORKSPACE_SWITCHER_ENABLED) return null

  return (
    <Dropdown autoClose className={containerClassName}>
      <Dropdown.Toggle
        as={Button}
        variant='secondary'
        // @ts-expect-error
        size='medium'
        className={classes.dropdownToggle}
      >
        {!loading && <p>{currentWorkspaceName}</p>}
        {loading && <LoadingSpinner height='15px' width='15px' />}
      </Dropdown.Toggle>
      <Dropdown.Menu className={classes.dropdownContent} renderOnMount>
        {workspaces.map(workspace => (
          <Dropdown.Item
            as={Button}
            variant='link'
            isAnchor
            className={classes.option}
            key={workspace.id}
            onClick={() => { void handleWorkspaceChange(workspace.id) }}
          >
            {workspace.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
