import classnames from 'classnames'
import { isEmpty, isFunction, isNil, isUndefined, uniq } from 'lodash-es'
import { useMemo } from 'react'

export type Variants<T = string> = T[] | T
interface Options {
  prefix?: string
  defaults?: Record<string, boolean | ((v: Variants) => boolean)>
  ignoreUndefinedVariants?: boolean
}

function useVariants (
  classes: Record<string, string>,
  variants: Variants,
  {
    prefix = '',
    defaults = {},
    ignoreUndefinedVariants = false
  }: Options = {}
): string {
  if (Array.isArray(variants)) {
    variants = [...variants]
  } else {
    variants = variants.split(' ')
  }

  return useMemo(() => {
    Object.entries(defaults).forEach(([key, shouldAdd]) => {
      if (isFunction(shouldAdd)) shouldAdd = shouldAdd(variants)
      if (shouldAdd) (variants as string[]).push(key)
    })

    const variantClasses: Record<string, string> = {}

    for (let variant of variants) {
      variant = `${prefix}${variant}`
      const className = classes[variant]

      if ((isEmpty(className)) && !ignoreUndefinedVariants) {
        // eslint-disable-next-line no-console
        console.error(`Variant ${variant} is not defined`, classes)
      }

      if (isNil(className)) continue

      variantClasses[className] = className
    }

    return classnames(variantClasses)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, variants.toString()])
}

function combineVariants<T> (...variants: Array<Variants<T>>): Variants<Exclude<T, undefined>> {
  let result: Variants<T> = []

  for (let variant of variants) {
    if (!Array.isArray(variant)) {
      variant = [variant]
    }

    result = result.concat(variant)
  }

  return uniq(result)
    .filter(
      (v): v is Exclude<T, undefined> => !isUndefined(v)
    )
}

function variantSwitch<T extends string> (variant: Variants<T>, options: Record<string, any>): any {
  for (const [key, value] of Object.entries(options)) {
    if (isVariant(variant, key)) return value
  }
}

function isVariant<T> (variants: Variants<T>, variant: T): boolean {
  if (!Array.isArray(variants)) {
    variants = [variants]
  }

  return variants.includes(variant)
}

export {
  useVariants as default,
  combineVariants,
  isVariant,
  variantSwitch
}
