import classnames from 'classnames'
import { ReactElement } from 'react'

import useVariants, { Variants } from '~/utils/use_variants'

import classes from './styles.module.scss'

type VariantOptions = 'light' | 'dark'

interface LoadingSpinnerProps {
  className?: string
  variant?: Variants<VariantOptions>
  width?: string
  height?: string
}

function LoadingSpinner (props: LoadingSpinnerProps): ReactElement {
  let { className, width, height, variant = 'dark', ...restProps } = props

  className = classnames(
    classes.spinner,
    useVariants(classes, variant, { prefix: 'variant_' }),
    className
  )

  return (
    <div
      role='presentation'
      className={className}
      style={{ width, height }}
      {...restProps}
    />
  )
}

export default LoadingSpinner
