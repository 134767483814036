import * as Yup from 'yup'

export const initialValues = {
  email: '',
  password: ''
}

export function getSchema (): Yup.AnyObjectSchema {
  return Yup.object({
    email: Yup.string().email('Must be a valid email').required('Must enter an email'),
    password: Yup
      .string()
      .min(6, 'Must be at least 6 characters')
      .required('Must enter a password')
  })
}
