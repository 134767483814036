import classNames from 'classnames'
import { ReactElement, useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import useUpgradeAnonymousUser from '~/authentication/hooks/useUpgradeAnonymousUser'
import SignUpModal from '~/authentication/pages/SignUpModal'
import { useCurrentUser } from '~/main_app/hooks/useCurrentUser'
import current_user from '~/service_providers/graphql/queries/current_user'
import useTranslate from '~/service_providers/i18n/useTranslate'
import LegacyButton from '../LegacyButton'

import { isNil } from 'lodash'
import isCurrentPage from '../TopBarNavigation/helpers/isCurrentPage'
import classes from './styles.module.scss'

interface InviteBannerProps {
  bannerContainerClassName?: string
}

export function InviteBanner (props: InviteBannerProps): ReactElement | null {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false)
  const { signUpWithGoogle, signUpWithEmailAndPassword } = useUpgradeAnonymousUser()
  const { translate } = useTranslate()
  const { user, loading: loadingUser, refetch: refetchUseCurrentUser } = useCurrentUser(current_user, { returnDataOnError: true })

  const { bannerContainerClassName } = props

  const handleSignUpWithGoogle = useCallback(async () => {
    const result = await signUpWithGoogle()
    if (result.isErr()) {
      toast.error(translate('An error ocurred while trying to sign you up'))
      return
    }

    toast.success(translate('You have successfully signed up'))

    await refetchUseCurrentUser()
    setIsSignUpModalOpen(false)
  }, [refetchUseCurrentUser, signUpWithGoogle, translate])

  const handleSignUpWithEmailAndPassword = useCallback(async (values: { email: string, password: string }) => {
    const result = await signUpWithEmailAndPassword(values.email, values.password)
    if (result.isErr()) {
      toast.error(translate('An error ocurred while trying to sign you up'))
      return
    }

    toast.success(translate('You have successfully signed up'))

    await refetchUseCurrentUser()
    setIsSignUpModalOpen(false)
  }, [refetchUseCurrentUser, signUpWithEmailAndPassword, translate])

  if (loadingUser || isCurrentPage('/start-options')) return null

  if (isNil(user?.isUserAnonymous) || user?.isUserAnonymous === false) { return null }

  return (
    <>
      <div className={classNames(bannerContainerClassName, classes.inviteBannerContainer)}>
        <div className={classes.inviteBanner}>
          <div className={classes.inviteBannerContent}>
            <span className={classes.inviteBannerText}>{translate('Don\'t lose your progress, create an account with us')}</span>
            <LegacyButton
              className={classes.inviteBannerActionButton}
              variant={['secondary', 'gray']}
              onClick={() => { setIsSignUpModalOpen(true) }}
            >
              {translate('Sign up')}
            </LegacyButton>
          </div>
        </div>
      </div>
      <SignUpModal
        isOpen={isSignUpModalOpen}
        onRequestClose={() => { setIsSignUpModalOpen(false) }}
        onGoogleSignUpButtonClicked={handleSignUpWithGoogle}
        onCreateAccountButtonClicked={handleSignUpWithEmailAndPassword}
      />
    </>
  )
}
