/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { FieldPolicy } from '@apollo/client'

import { PaginatedData, Pagination } from '@whr/pagination/types'
import { allKeyArgsExceptPagination, commonMerge } from '.'

interface Person {
  id: string
}
const meta = {
  wasPagePreviouslyVisited: false,
  lastUsedVariables: undefined
}

export const personsFieldPolicy: FieldPolicy<PaginatedData<'people', Person>> = {
  keyArgs: allKeyArgsExceptPagination,
  merge (existing = { people: [], pagination: {} as Pagination }, incoming, { variables }) {
    return commonMerge('people', existing, incoming, variables, meta)
  }
}
