import AuthenticationError from './authentication_error'

enum BlockedPopUpsErrorCodes {
  BLOCKED_POPUP = 'auth/popups-blocked'
}

export default class BlockedPopUpsError extends AuthenticationError<BlockedPopUpsErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, BlockedPopUpsErrorCodes.BLOCKED_POPUP)
  }
}
