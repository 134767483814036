import { Result, ok } from '@whr/core_utils/result'
import { useCallback, useState } from 'react'
import { LocalStorageKeyNotSetError } from './errors'
import { Key, LocalStorageKeys, localStorageProvider } from './provider'

interface UseLocalStorageUtilityFunctions {
  refetch: () => void
  remove: () => void
  provider: typeof localStorageProvider
}

type UseLocalStorageHookResult = [
  value: string | undefined,
  set: (value: string) => Result<void, LocalStorageKeyNotSetError>,
  utils: UseLocalStorageUtilityFunctions
]

export function useLocalStorage (key: Key<LocalStorageKeys>): UseLocalStorageHookResult {
  const [value, setValue] = useState(localStorageProvider.get(key))

  const set = useCallback<(newValue: string) => Result<void, LocalStorageKeyNotSetError>>((newValue: string) => {
    const result = localStorageProvider.set(key, newValue)

    if (result.isErr()) {
      return result.into()
    }

    setValue(value)

    return ok()
  }, [key, setValue, value])

  const remove = useCallback(() => {
    localStorageProvider.remove(key)

    setValue(undefined)
  }, [key, setValue])

  const refetch = useCallback(() => {
    const newValue = localStorageProvider.get(key)

    setValue(newValue)
  }, [key])

  return [value, set, { refetch, remove, provider: localStorageProvider }]
}
