import Image from 'next/image'
import { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react'
import ReactModal from 'react-modal'
import { XOR } from 'ts-xor'

import { IS_CSR } from '~/constants'

import CloseBlack from '~/assets/icons/close-black.svg'

import classNames from 'classnames'
import { isEmpty, isUndefined, omit } from 'lodash-es'
import useTranslate from '~/service_providers/i18n/useTranslate'
import LegacyButton from '../LegacyButton'
import classes from './styles.module.scss'

if (IS_CSR) {
  ReactModal.setAppElement('#__next')
}

type ReactModalProps = ComponentPropsWithoutRef<typeof ReactModal>
interface BaseModalProps {
  id?: string
  isOpen?: boolean
  children: ReactNode
  className?: string
  onRequestClose?: ReactModalProps['onRequestClose']
  header?: ReactElement | ReactElement[]
  noCloseButton?: boolean
  isCloser?: boolean
}
type ModalProps = XOR<
BaseModalProps & { labelledBy: string },
BaseModalProps & { label: string }
>

function Modal (props: ModalProps): ReactElement {
  const {
    id,
    isOpen = true,
    children,
    className,
    labelledBy,
    label,
    header,
    noCloseButton = false,
    onRequestClose,
    isCloser = false
  } = props

  const { translate } = useTranslate()

  const extraProps: Pick<ReactModalProps, 'aria'> = {}

  if (!isEmpty(labelledBy)) {
    extraProps.aria = { labelledby: labelledBy }
  }

  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      overlayElement={(props, children) => (
        <div
          {...omit(props, 'style')}
          className={classNames(classes.overlay, {
            [classes.closerOverlay ?? '']: isCloser
          })}
          onClick={(e) => {
            e.stopPropagation()
            props.onClick?.(e)
          }}
        >
          {children}
        </div>
      )}
      contentElement={(props, children) => (
        <div
          {...omit(props, 'style')}
          className={classNames(classes.modal, className)}
        >
          {children}
        </div>
      )}
      bodyOpenClassName={classes.modalOpen}
      onRequestClose={onRequestClose}
      contentLabel={label}
      {...extraProps}
    >
      {!isUndefined(header) && header}
      {!noCloseButton && (
        <LegacyButton
          onClick={onRequestClose}
          title={translate('Close')}
          variant='icon'
          className={classes.close}
        >
          <Image
            src={CloseBlack}
            alt={translate('Close the modal')}
            width='14'
            height='14'
          />
        </LegacyButton>
      )}
      {children}
    </ReactModal>
  )
}

export default Modal
