import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

export const requiredPermissions: SubjectActionsCollection<'RecruitingAgency'> = [
  ['RecruitingAgency', ['read']]
]

export function useCanManageRecruitingAgency (): {
  error?: Error
  loading: boolean
  isAuthorized: boolean
} {
  const { authorizations, loading, error } = useVerifyAuthorizations(requiredPermissions)

  return {
    error,
    loading,
    isAuthorized: authorizations?.isFullyAuthorized ?? false
  }
}
