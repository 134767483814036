import { ApolloClient, InMemoryCache } from '@apollo/client'

import authLink from './auth_link'
import splitLink from './link'
import { typePolicies } from './type_policies/index'

const apolloClient = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache({ typePolicies })
})

export default apolloClient
