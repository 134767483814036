import abind from 'abind'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApiBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { TranslationNamespace } from '@whr/entities/internationalization/index.js'
import { difference } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { BACKEND_BASE_URL } from '~/constants'
import { getLanguages } from './helpers/languages'
import { II18NProvider } from './provider'
import { TranslationOptions } from './useTranslate'

export class I18NProvider implements II18NProvider {
  private readonly baseUrl: string = BACKEND_BASE_URL

  constructor () {
    abind(this)
  }

  async init (): Promise<I18NProvider> {
    const backend = new HttpApiBackend(null, {
      loadPath: `${this.baseUrl}/locales/{{lng}}/{{ns}}.json`,
      addPath: `${this.baseUrl}/locales/add/{{lng}}/{{ns}}`
    })

    const languagesResult = await getLanguages()
    let languages: ['en', ...string[]] = ['en']

    if (languagesResult.isOk()) {
      if (!isEmpty(languagesResult.value)) {
        languages = [...languages, ...difference(languagesResult.value, languages)]
      }
    }

    await i18next
      .use(backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en',
        ns: Object.values(TranslationNamespace),
        fallbackNS: 'common',
        defaultNS: 'translation',
        debug: false,
        saveMissing: true,
        load: 'languageOnly',
        saveMissingPlurals: false,
        supportedLngs: languages,
        preload: languages
      })

    return this
  }

  getLanguage (): string {
    return i18next.resolvedLanguage ?? 'en'
  }

  async setLanguage (language: string): Promise<void> {
    await i18next.changeLanguage(language)
  }

  translate (message: string, options?: TranslationOptions): string {
    return i18next.t(message, options)
  }
}
