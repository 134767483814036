import { useCallback, useEffect, useRef, useState } from 'react'
import { Action } from '~/service_providers/authorization/actions'
import { Validator } from '~/service_providers/authorization/validator'

interface UseAuthorizationResultProps {
  authorize: (action?: Action) => Promise<boolean> | boolean
  isAuthorized: boolean
  loading: boolean
  error: Error | undefined
  failedValidator: Validator | undefined
  called: boolean
}

interface UseAuthorizationProps {
  lazy?: boolean
  action?: Action
  validators: Validator[]
  onAuthorizationAccepted?: () => void
  onAuthorizationRejected?: (action: Action | undefined, validator: Validator | undefined) => void
  onError?: (error: Error) => void
}

export default function useAuthorization (props: UseAuthorizationProps): UseAuthorizationResultProps {
  const {
    lazy,
    action,
    validators,
    onAuthorizationAccepted,
    onAuthorizationRejected,
    onError
  } = props

  const [failedValidator, setFailedValidator] = useState<Validator | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | undefined>()
  const [authorizeCalled, setAuthorizeCalled] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const isUnmountedRef = useRef<boolean>(false)

  const authorize = useCallback(async (actionOverride?: Action) => {
    setFailedValidator(undefined)

    try {
      const res: boolean[] = []
      await Promise.all(validators.map(async validator => {
        const response = await validator.validate(actionOverride ?? action)
        if (response.isErr()) throw response.error

        res.push(response.value)
      }))
      const failedValidatorIndex = res.findIndex(r => !r)
      const isAuthorized = failedValidatorIndex === -1

      if (isUnmountedRef.current) return isAuthorized
      setIsAuthorized(isAuthorized)
      setError(undefined)

      if (!isAuthorized) {
        onAuthorizationRejected?.(action, validators[failedValidatorIndex])
        setFailedValidator(validators[failedValidatorIndex])
      } else {
        onAuthorizationAccepted?.()
      }

      return isAuthorized
    } catch (err: any) {
      if (isUnmountedRef.current) throw err
      onError?.(err)
      setError(err)
      throw err
    } finally {
      if (!isUnmountedRef.current) setLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, String(validators), onAuthorizationAccepted, onAuthorizationRejected, onError])

  useEffect(() => {
    isUnmountedRef.current = false
    return () => {
      isUnmountedRef.current = true
    }
  }, [])

  useEffect(() => {
    if (lazy === true || authorizeCalled) {
      return
    }

    authorize().catch(() => {})
    setAuthorizeCalled(true)
  }, [authorize, lazy, authorizeCalled])

  useEffect(() => {
    const changeEventUnsubscribers = validators.map(validator => validator.on('change', () => {
      authorize().catch(() => {})
    }))

    return () => {
      changeEventUnsubscribers.forEach(unsubscribe => { unsubscribe() })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorize, String(validators)])

  return {
    isAuthorized,
    loading,
    error,
    failedValidator,
    authorize,
    called: authorizeCalled
  }
}
