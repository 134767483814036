import { err, ok } from '@whr/core_utils/result'
import { useCallback } from 'react'
import { UpgradeAnonymousUserWithEmailAndPassword, useUpgradeAnonymousUserWithEmailAndPassword } from '../useUpgradeAnonymousUserWithEmailAndPassword'
import { UpgradeAnonymousUserWithGoogle, useUpgradeAnonymousUserWithGoogle } from '../useUpgradeAnonymousUserWithGoogle'

interface HookResult {
  signUpWithGoogle: UpgradeAnonymousUserWithGoogle
  signUpWithEmailAndPassword: UpgradeAnonymousUserWithEmailAndPassword
}

export default function useUpgradeAnonymousUser (): HookResult {
  const { upgradeAnonymousUserWithGoogle } = useUpgradeAnonymousUserWithGoogle()
  const { upgradeAnonymousUserWithEmailAndPassword } = useUpgradeAnonymousUserWithEmailAndPassword()

  const handleSignUpWithGoogle = useCallback<UpgradeAnonymousUserWithGoogle>(
    async () => {
      const upgradeAnonymousUserWithGoogleResult = await upgradeAnonymousUserWithGoogle()
      if (upgradeAnonymousUserWithGoogleResult.isErr()) {
        return err(upgradeAnonymousUserWithGoogleResult.error)
      }

      return ok()
    }, [upgradeAnonymousUserWithGoogle]
  )

  const handleSignUpWithEmailAndPassword = useCallback<UpgradeAnonymousUserWithEmailAndPassword>(
    async (email: string, password: string) => {
      const upgradeAnonymousUserWithEmailAndPasswordResult = await upgradeAnonymousUserWithEmailAndPassword(email, password)
      if (upgradeAnonymousUserWithEmailAndPasswordResult.isErr()) {
        return err(upgradeAnonymousUserWithEmailAndPasswordResult.error)
      }

      return ok()
    }, [upgradeAnonymousUserWithEmailAndPassword]
  )

  return {
    signUpWithGoogle: handleSignUpWithGoogle,
    signUpWithEmailAndPassword: handleSignUpWithEmailAndPassword
  }
}
