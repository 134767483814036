import { useEffect, useState } from 'react'
import useAuthenticationProvider from './useAuthenticationProvider'
import { MissingDependencyError } from '@whr/core_utils/missingDependencyError'
import { isNil } from 'lodash'
import { AuthenticationErrors } from '~/service_providers/authentication/authentication_provider'

interface HookResult {
  tenantId?: string
  loading: boolean
  error?: Error | MissingDependencyError | AuthenticationErrors
}

export function useCurrentTenant (): HookResult {
  const [tenantId, setTenantId] = useState<string | undefined>(undefined)
  const [getterError, setGetterError] = useState<AuthenticationErrors | undefined>(undefined)
  const { loading, error, authenticationProvider } = useAuthenticationProvider()

  useEffect(() => {
    if (isNil(error) && !loading && !isNil(authenticationProvider)) {
      const providedTenant = authenticationProvider.getTenantId()
      if (providedTenant.isOk()) {
        setTenantId(providedTenant.value)
      } else {
        setGetterError(providedTenant.error)
      }
    }
  }, [error, loading, authenticationProvider])

  return { tenantId, error: error ?? getterError, loading }
}
