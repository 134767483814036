import { gql } from '@apollo/client'
import { AsyncResult, err, ok } from '@whr/core_utils/result'
import { AuthorizationEntity, AuthorizationSubjectsMetadata } from '@whr/entities/authorization'
import isNil from 'lodash/isNil'
import UnexpectedError from '~/errors/unexpected_error'
import apolloClient from '../../service_providers/graphql/configurations/client'

interface AuthorizationSubjectDTO {
  id: string
  name: string
  protectedFields: string[]
}

interface QueryResult {
  authorizationSubject: AuthorizationSubjectDTO
  currentUser: { authorizationSubjectActions: AuthorizationSubjectActionsDTO[] }
}

interface AuthorizationSubjectActionsDTO {
  action: { id: string, name: string }
  field?: string
}

export default async function getCurrentUserAuthorizationEntity<Subject extends keyof AuthorizationSubjectsMetadata> (
  subjectId: Subject
): AsyncResult<
  AuthorizationEntity<AuthorizationSubjectsMetadata[Subject]['actions'], AuthorizationSubjectsMetadata[Subject]['dto']>,
  UnexpectedError
  > {
  try {
    const result = await apolloClient.query<QueryResult>({
      variables: { subjectId },
      query: gql`
        query currentUserAuthorizationSubject($subjectId: String!) {
          authorizationSubject(id: $subjectId) {
            id
            name
            protectedFields
          }
          currentUser {
            id
            authorizationSubjectActions(subjectId: $subjectId) {
              action {
                id
                name
              }
              field
            }
          }
        }
      `
    })

    if (!isNil(result.error) || !isNil(result.errors)) {
      return err(new UnexpectedError('An unexpected error occurred while trying to get the authorization subject actions', result.error ?? result.errors?.[0]))
    }

    return ok(new AuthorizationEntity(result.data.authorizationSubject, result.data.currentUser.authorizationSubjectActions.map(a => ({ actionId: a.action.id, field: a.field }))))
  } catch (error) {
    return err(new UnexpectedError('An unexpected error occurred while trying to get the authorization subject actions'))
  }
}
