import { useFormikContext } from 'formik'
import { isUndefined } from 'lodash-es'
import { ElementType, ForwardedRef, ReactElement, forwardRef, useMemo } from 'react'

import { combineVariants } from '~/utils/use_variants'

import LegacyButton, { LegacyButtonProps } from '../LegacyButton'
import LoadingSpinner from '../LoadingSpinner'

const SubmitButton = forwardRef(SubmitButtonInner) as <
  ButtonComponentType extends ElementType
>(
  props: LegacyButtonProps<ButtonComponentType> & Partial<{ name: string, value: string }>
) => ReactElement

function SubmitButtonInner<ButtonComponentType extends ElementType> (
  props: LegacyButtonProps<ButtonComponentType> & Partial<{ name: string, value: string }>,
  ref: ForwardedRef<ButtonComponentType>
): ReactElement {
  let { variant = 'primary', iconLeft } = props
  const { isSubmitting, isValidating, setFieldValue, values, isValid } = useFormikContext<Record<string, string>>()
  const hasNameAndValue = !isUndefined(props.name) && !isUndefined(props.value)
  const isActive = !hasNameAndValue || (!isUndefined(props.name) && values[props.name] === props.value)

  variant = useMemo(() => {
    if (!isValid) {
      return combineVariants(variant, 'disabled')
    }

    return variant
  }, [variant, isValid])

  return (
    <LegacyButton<ButtonComponentType>
      type='submit'
      disabled={isSubmitting || isValidating}
      ref={ref}
      {...props}
      variant={variant}
      iconLeft={isSubmitting && isActive ? <LoadingSpinner variant='dark' /> : iconLeft}
      onClick={(...args: any[]) => {
        if (hasNameAndValue && !isUndefined(props.name)) {
          setFieldValue(props.name, props.value)
        }
        props.onClick?.(...args)
      }}
    />
  )
}

export default SubmitButton
