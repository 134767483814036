import AuthenticationError from './authentication_error'

enum CancelledPopUpErrorCodes {
  POPUP_CANCELLED = 'auth/popup-cancelled'
}

export default class CancelledPopUpError extends AuthenticationError<CancelledPopUpErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, CancelledPopUpErrorCodes.POPUP_CANCELLED)
  }
}
