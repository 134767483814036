import { isNull } from 'lodash-es'

export const IS_LOCAL = process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
export const IS_TEST = process.env.NODE_ENV === 'test'
export const IS_SSR = typeof window === 'undefined'
export const IS_CSR = !IS_SSR && !IS_TEST && !isNull(document.getElementById('__next'))

export const BACKEND_GRAPHQL_URL = process.env.NEXT_PUBLIC_BACKEND_GRAPHQL_URL

export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
export const FIREBASE_DATABASE_URL = process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL
export const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID = process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
export const FIREBASE_VAPID_KEY = process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY

export const MAX_FILE_UPLOAD_SIZE_BYTES = parseInt(process.env.NEXT_PUBLIC_MAX_FILE_UPLOAD_SIZE_BYTES)

export const WE_DEVELOP_RECRUITING_AGENCY_ID = 'c43935c0-0838-4c66-b436-2a197de8b9c1'

export const MINIMUM_ACCEPTED_PAYONEER_PAYMENT_IN_CENTS = 2000
export const PAYONEER_PAYMENT_METHOD_ID = '3ddcebd6-98d1-4d98-8968-c17288a1513a'
export const OFFLINE_PAYMENT_METHOD_ID = '460c034f-97d7-44e8-8ecf-fc97d3452668'

export const FRESHBOOKS_INVOICE_CREATION_IN_PROJECT_ASSIGNMENTS_PAGE_ENABLED = process.env.NEXT_PUBLIC_FRESHBOOKS_INVOICE_CREATION_IN_PROJECT_ASSIGNMENTS_PAGE_ENABLED === 'true'
export const WEDEVELOP_TENANT_ID = process.env.NEXT_PUBLIC_WEDEVELOP_TENANT_ID
export const IS_BIZNEO_CONFIGURED = process.env.NEXT_PUBLIC_IS_BIZNEO_CONFIGURED === 'true'
export const SHOW_CONTRACTOR_DETAILS = process.env.NEXT_PUBLIC_SHOW_CONTRACTOR_DETAILS === 'true'
export const APP_NAME = 'nubel.app'
export const APP_VERSION = '0.0.1'
export const BACKEND_BASE_URL = process.env.NEXT_PUBLIC_BACKEND_BASE_URL ?? ''
export const LINKEDIN_BASE_URL = 'https://www.linkedin.com'
export const LINKEDIN_SCRAPER_EXTENSION_URL = 'https://chrome.google.com/webstore/detail/nubel-linkedin-profile-im/nkelmdcgnbadafjhncggelbijhgfilke'
export const LINKEDIN_SCRAPER_EXTENSION_ID = process.env.NEXT_PUBLIC_LINKEDIN_SCRAPER_EXTENSION_ID
