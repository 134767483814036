import { CommonCacheTTLMs, ConfigurationSpecification, Scope } from '@whr/configuration_service/types'

const defaultSpecOptions = {
  storageProvider: 'static',
  scope: Scope.GLOBAL,
  cacheTTLMs: CommonCacheTTLMs.ONE_WEEK
}

export const specifications: Record<string, ConfigurationSpecification> = {
  MAX_FILE_UPLOAD_SIZE_BYTES: defaultSpecOptions,
  WE_DEVELOP_RECRUITING_AGENCY_ID: defaultSpecOptions,
  MINIMUM_ACCEPTED_PAYONEER_PAYMENT_IN_CENTS: defaultSpecOptions,
  PAYONEER_PAYMENT_METHOD_ID: defaultSpecOptions,
  OFFLINE_PAYMENT_METHOD_ID: defaultSpecOptions,
  APP_NAME: defaultSpecOptions,
  APP_VERSION: defaultSpecOptions,
  BACKEND_BASE_URL: defaultSpecOptions
}
