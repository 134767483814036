import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

interface HookResult {
  loading: boolean
  error?: Error
  isAuthorized: boolean
}

export const requiredPermissions: SubjectActionsCollection<'Client' | 'Billing::Settings'> = [
  ['Client', ['read']],
  ['Billing::Settings', ['read']]
]

export default function useCanReadBillableEntities (): HookResult {
  const { loading, error, authorizations } = useVerifyAuthorizations(requiredPermissions)

  return {
    isAuthorized: authorizations?.isFullyAuthorized ?? false,
    loading,
    error
  }
}
