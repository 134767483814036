import { TypePolicies } from '@apollo/client'

import { PaginatedData } from '@whr/pagination/types'
import deepEqual from 'deep-equal'
import { isNil, omit } from 'lodash'
import { personsFieldPolicy } from './persons'
import { projectAssignmentsFieldPolicy } from './project_assignments'

interface CacheMergeMeta {
  wasPagePreviouslyVisited: boolean
  lastUsedVariables: Record<string, any> | undefined
}

export function allKeyArgsExceptPagination (keyArgs: Record<string, any> | null): string[] {
  return Object.keys(omit(keyArgs, 'pagination'))
}

export function commonMerge<DataKey extends PropertyKey, DataType, TResult extends PaginatedData<DataKey, DataType>> (
  dataKey: DataKey,
  existing: TResult,
  incoming: TResult,
  variables: Record<string, any> | undefined,
  meta: CacheMergeMeta
): TResult {
  const isFirstRequest = isNil(variables?.pagination?.cursor)

  if (isFirstRequest && !meta.wasPagePreviouslyVisited) {
    meta.lastUsedVariables = variables
    meta.wasPagePreviouslyVisited = true
    return incoming
  }

  const lastUsedVariablesWithoutPagination = omit(meta.lastUsedVariables, 'pagination')
  const incomingVariablesWithoutPagination = omit(variables, 'pagination')
  const isTheSameRequestAsLast = deepEqual(lastUsedVariablesWithoutPagination, incomingVariablesWithoutPagination)

  meta.lastUsedVariables = variables

  if (!isFirstRequest && isTheSameRequestAsLast) {
    // @ts-expect-error
    return {
      [dataKey]: [...existing[dataKey], ...incoming[dataKey]],
      pagination: incoming.pagination
    }
  }

  if (!isFirstRequest && !isTheSameRequestAsLast) {
    return incoming
  }

  if (isFirstRequest && isTheSameRequestAsLast && meta.wasPagePreviouslyVisited) {
    return existing
  }

  if (!isTheSameRequestAsLast && meta.wasPagePreviouslyVisited) {
    return incoming
  }

  // @ts-expect-error
  return {
    [dataKey]: [...existing[dataKey], ...incoming[dataKey]],
    pagination: incoming.pagination
  }
}

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      projectAssignments: projectAssignmentsFieldPolicy,
      persons: personsFieldPolicy
    }
  },
  PersonPaymentMethod: {
    keyFields: false
  }
}
