import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

export const requiredPermissions: SubjectActionsCollection<'ATS::Offering'> = [
  ['ATS::Offering', ['read']]
]

export function useCanAccessAtsList (): {
  error?: Error
  loading: boolean
  isAuthorized: boolean
} {
  const { authorizations, loading, error } = useVerifyAuthorizations(requiredPermissions)

  return {
    error,
    loading,
    isAuthorized: authorizations?.isFullyAuthorized ?? false
  }
}
