import { setContext } from '@apollo/client/link/context'
import { isNil } from 'lodash-es'
import { getAuthenticationProvider } from '~/service_providers/authentication/authentication_provider'
import { tokenInitializedPromise } from '~/service_providers/firebase/initialize_firebase'

const authLink = setContext(async (_, { headers }) => {
  await tokenInitializedPromise

  const authProvider = getAuthenticationProvider()

  let token
  const tenantIdHeader: { 'X-Tenant-Id'?: string } = {}

  if (authProvider.isOk()) {
    const tenantId = authProvider.value.getTenantId()

    if (tenantId.isOk() && !isNil(tenantId.value)) {
      tenantIdHeader['X-Tenant-Id'] = tenantId.value
    }

    const tokenResult = await authProvider.value.getToken()
    if (tokenResult.isOk()) {
      token = tokenResult.value
    }
  }

  return {
    headers: {
      ...headers,
      ...tenantIdHeader,
      authorization: !isNil(token) ? `Bearer ${token}` : ''
    },
    credentials: 'include'
  }
})

export default authLink
