import { ReactElement } from 'react'

import Form, { FormProps } from '~/main_app/components/Form'

import { initialValues as defaultInitialValues } from './schema'

type BaseSignUpFormProps<Values> = (
  Omit<FormProps<Values>, 'initialValues'> &
  Partial<{ initialValues: Partial<FormProps<Values>['initialValues']> }>
)

export type SignUpFormProps = BaseSignUpFormProps<typeof defaultInitialValues>

export default function SignUpForm (props: SignUpFormProps): ReactElement {
  const { initialValues, ...restProps } = props

  return (
    <Form
      initialValues={{ ...defaultInitialValues, ...initialValues }}
      {...restProps}
    />
  )
}
