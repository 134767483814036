import AuthenticationError from './authentication_error'

enum InvalidActionCodeErrorCodes {
  INVALID_ACTION_CODE = 'auth/invalid-action-code'
}

export default class InvalidActionCodeError extends AuthenticationError<InvalidActionCodeErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, InvalidActionCodeErrorCodes.INVALID_ACTION_CODE)
  }
}
