import { isUndefined } from 'lodash-es'
import NotGuard from './guards/not_guard'
import { Validator } from './validator'
import validators from './validators'

type ValidatorName = keyof typeof validators

type Validators = {
  [key in ValidatorName]: Validator
} & {
  not: {
    [key in ValidatorName]: Validator
  }
}

const guardsMap = new Map<ValidatorName, Validator>()

function getValidator (name: ValidatorName): Validator {
  let validator = guardsMap.get(name)

  if (isUndefined(validator)) {
    validator = new validators[name]()
    guardsMap.set(name, validator)
  }

  return validator
}

const guards = new Proxy({}, {
  get (target, name) {
    if (name === 'not') {
      return new Proxy({}, {
        get (target, name) {
          return new NotGuard(getValidator(name as ValidatorName))
        }
      })
    }

    if (name in validators) {
      return getValidator(name as ValidatorName)
    } else {
      return Reflect.get(target, name)
    }
  }
}) as Validators

export default guards
