import AuthenticationError from './authentication_error'

enum PopUpClosedErrorCodes {
  POPUP_CLOSED = 'auth/popup-closed'
}

export default class PopUpClosedError extends AuthenticationError<PopUpClosedErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, PopUpClosedErrorCodes.POPUP_CLOSED)
  }
}
