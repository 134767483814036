import { isEmpty } from 'lodash'
import Image from 'next/image'
import Router from 'next/router'
import { ReactElement, useCallback } from 'react'
import { Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'

import PrimaryChevronDown from '~/assets/icons/primary_100_chevron_down.svg'
import useSignOut from '~/authentication/hooks/useSignOut'
import { UseCurrentUserResult } from '~/main_app/hooks/useCurrentUser'
import useTranslate from '~/service_providers/i18n/useTranslate'
import Avatar from '../Avatar'
import DropdownMenu from '../DropdownMenu'
import InternalLink from '../InternalLink'
import LegacyButton from '../LegacyButton'
import LoadingSpinner from '../LoadingSpinner'

import classes from './styles.module.scss'

interface ProfileMenuProps {
  user?: UseCurrentUserResult['user']
  loading: boolean
}

export default function ProfileMenu (props: ProfileMenuProps): ReactElement {
  const { user, loading } = props
  const { translate } = useTranslate()
  const { signOut } = useSignOut()

  const onSignOutClick = useCallback(async () => {
    const result = await signOut()
    if (result.isErr()) {
      toast.error(translate('An error occurred while logging out.'))
      return
    }

    await Router.push('/auth/login')
  }, [signOut, translate])

  return (
    <>
      <div className={classes.avatarContainer}>
        {loading &&
          <div className={classes.spinnerContainer}>
            <LoadingSpinner />
          </div>}

      </div>
      <DropdownMenu
        autoClose
        label={
          <LegacyButton
            aria-label={translate('Button to expand user menu')}
            variant='icon'
          >
            <div className={classes.avatarAndArrowContainer}>
              <Avatar
                profilePictureURL={user?.profilePictureURL}
                displayName={user?.firstName}
                className={classes.avatarSmall}
              />
              <Image
                src={PrimaryChevronDown}
                height={16}
                width={16}
                alt={translate('Primary chevron')}
                className={classes.dropdownToggleIcon}
              />
            </div>
          </LegacyButton>
      }
        className={classes.dropdownContainer}
        dropDownMenuClassName={classes.dropdownOptions}
      >
        <div className={classes.contextMenuContainer}>
          <div className={classes.imageContainer}>
            {!loading &&
              <>
                <Avatar
                  profilePictureURL={user?.profilePictureURL}
                  displayName={user?.firstName}
                  className={classes.avatar}
                />
                <div className={classes.filledAvatarCircle} aria-hidden='true' />
                <div className={classes.emptyAvatarCircle} aria-hidden='true' />
              </>}
          </div>
          <div className={classes.menuOptions}>
            {
            !isEmpty(user?.firstName) && (
              <Dropdown.Item as='div' className={classes.userName}>
                <p>{user?.firstName ?? translate('User')}</p>
              </Dropdown.Item>
            )
          }
            <Dropdown.Item as='div' className={classes.useEmail}>
              <p>{user?.email}</p>
            </Dropdown.Item>
            <Dropdown.Item as={InternalLink} href='/profile-preferences' className={classes.profileAndPreferences}>
              <p>{translate('Profile & Preferences')}</p>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={classes.logOutButton} onClick={() => { void onSignOutClick() }}>
              <p>{translate('Log Out')}</p>
            </Dropdown.Item>
          </div>
        </div>

      </DropdownMenu>
    </>
  )
}
