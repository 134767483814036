import { AsyncResult, err, ok } from '@whr/core_utils/result'
import { useCallback } from 'react'
import UnexpectedError from '~/errors/unexpected_error'
import { getAuthenticationProvider } from '~/service_providers/authentication/authentication_provider'

type SignOut = () => AsyncResult<void, UnexpectedError>

export default function useSignOut (): { signOut: SignOut } {
  const signOut = useCallback<SignOut>(async () => {
    const authProvider = getAuthenticationProvider()

    if (authProvider.isErr()) {
      return err(new UnexpectedError('Could not reach the Auth provider', authProvider.error))
    }

    const signOutResult = await authProvider.value.signOut()

    if (signOutResult.isErr()) {
      return err(new UnexpectedError('Could not sign out', signOutResult.error))
    }

    return ok()
  }, [])

  return { signOut }
}
