import { getApps, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { isSupported as isFirebaseMessagingSupported } from 'firebase/messaging'
import { omit } from 'lodash-es'

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  IS_LOCAL
} from '~/constants'

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
}

let settleTokenInitializedPromise: ((v?: unknown) => void) | null
export const tokenInitializedPromise = new Promise(resolve => { settleTokenInitializedPromise = resolve })

export default async function initializeFirebase (): Promise<void> {
  if (getApps().length > 0) return undefined

  if (IS_LOCAL) {
    initializeApp({
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      projectId: 'demo-test'
    })
    initializeApp(firebaseConfig, 'fcmApp')

    const auth = getAuth()
    connectAuthEmulator(auth, 'http://localhost:9099')

    const firestore = getFirestore()
    connectFirestoreEmulator(firestore, 'localhost', 8080)
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (await isFirebaseMessagingSupported()) {
      initializeApp(firebaseConfig)
    } else {
      initializeApp(omit(firebaseConfig, 'messagingSenderId'))
    }
  }

  getAuth().onAuthStateChanged(() => {
    settleTokenInitializedPromise?.()
    settleTokenInitializedPromise = null
  })
}
