import { gql } from '@apollo/client'

export default gql`
query Configuration($key: String!) {
  configuration(key: $key) {
    key
    value
  }
}
`
