import { MissingDependencyError } from '@whr/core_utils/missingDependencyError'
import { AsyncResult, err, ok } from '@whr/core_utils/result'
import { useCallback } from 'react'
import { AuthenticationErrors, getAuthenticationProvider } from '~/service_providers/authentication/authentication_provider'

export type UpgradeAnonymousUserWithEmailAndPassword = (email: string, password: string) => AsyncResult<void, AuthenticationErrors | MissingDependencyError>

interface UseUpgradeAnonymousUserWithEmailAndPasswordHookResult {
  upgradeAnonymousUserWithEmailAndPassword: UpgradeAnonymousUserWithEmailAndPassword
}

export function useUpgradeAnonymousUserWithEmailAndPassword (): UseUpgradeAnonymousUserWithEmailAndPasswordHookResult {
  const upgradeAnonymousUserWithEmailAndPassword = useCallback<UpgradeAnonymousUserWithEmailAndPassword>(async (email: string, password: string) => {
    const authProvider = getAuthenticationProvider()

    if (authProvider.isErr()) {
      return err(authProvider.error)
    }

    const upgradeResult = await authProvider.value.upgradeAnonymousUserWithEmailAndPassword(email, password)

    if (upgradeResult.isErr()) {
      return err(upgradeResult.error)
    }

    return ok()
  }, [])

  return { upgradeAnonymousUserWithEmailAndPassword }
}
