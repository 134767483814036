import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

interface HookResult {
  loading: boolean
  error?: Error
  isAuthorized: boolean
}

export const requiredPermissions: SubjectActionsCollection<'RecruitingAgency' | 'RecruitingAgency::InitialHire'> = [
  ['RecruitingAgency', ['read']],
  ['RecruitingAgency::InitialHire', ['read']]
]

export default function useViewRecruitingAgenciesPage (): HookResult {
  const { loading, error, authorizations } = useVerifyAuthorizations(requiredPermissions)

  return {
    loading,
    error,
    isAuthorized: authorizations?.isFullyAuthorized ?? false
  }
}
