import { ReactElement } from 'react'

import { UseCurrentUserResult } from '~/main_app/hooks/useCurrentUser'
import ProfileMenu from '../ProfileMenu'

import { WorkspaceSwitchChangeHandler, WorkspaceSwitcher } from '~/teams/components/WorkspaceSwitcher'

import { isEmpty } from 'lodash'
import classes from './styles.module.scss'

interface TopBarDesktopMenuProps {
  user: UseCurrentUserResult['user']
  currentTeam: string
  loadingTeam: boolean
  loadingUser: boolean
  onWorkspaceChange: WorkspaceSwitchChangeHandler
}

export default function TopBarDesktopMenu (props: TopBarDesktopMenuProps): ReactElement {
  const { user, loadingUser, loadingTeam, currentTeam, onWorkspaceChange } = props

  return (
    <div className={classes.menuContainer}>
      {!isEmpty(user?.teams) && (user?.teams?.length !== 1) &&
        <WorkspaceSwitcher
          workspaces={user?.teams ?? []}
          selectedWorkspaceId={currentTeam}
          loading={loadingTeam || loadingUser}
          onChange={onWorkspaceChange}
        />}
      <div className={classes.profileMenu}>
        <ProfileMenu
          user={user}
          loading={loadingUser}
        />
      </div>
    </div>
  )
}
