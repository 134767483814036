import BaseError from '@whr/core_utils/baseError'

enum AuthenticationErrorCodes {
  UNKNOWN = 'auth/unknown-error'
}

export default class AuthenticationError<ErrorCodes = AuthenticationErrorCodes> extends BaseError<ErrorCodes | AuthenticationErrorCodes> {
  constructor (message: string, originalError?: Error, code?: ErrorCodes) {
    super(code ?? AuthenticationErrorCodes.UNKNOWN, message, originalError)
  }
}
