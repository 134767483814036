import classNames from 'classnames'
import { ReactElement, ReactNode } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import LegacyButton from '../LegacyButton'

import classes from './styles.module.scss'

interface ComponentProps {
  label: ReactNode
  children: ReactNode
  className?: string
  dropDownMenuClassName?: string
  withoutItemsBackground?: boolean
  autoClose?: boolean | 'inside' | 'outside'
}

export default function DropdownMenu (props: ComponentProps): ReactElement {
  let dropDownMenuClassNameContainer
  const { label, children, className, dropDownMenuClassName, autoClose = 'outside' } = props
  const container = className

  if (props.withoutItemsBackground !== true) {
    dropDownMenuClassNameContainer = classNames(classes.dropDownContent, dropDownMenuClassName, classes.withItemsBackground)
  } else {
    dropDownMenuClassNameContainer = classNames(classes.dropDownContent, dropDownMenuClassName)
  }

  return (
    <Dropdown className={container} autoClose={autoClose}>
      {/* @ts-expect-error I don't know why Bootstrap is inferring the wrong type for the variant prop */}
      <Dropdown.Toggle as={LegacyButton} variant={['link', 'no-hover-focus-effects']} className={classes.dropdownToggle}>
        {label}
      </Dropdown.Toggle>

      <Dropdown.Menu className={dropDownMenuClassNameContainer} renderOnMount popperConfig={{ onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')) }}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  )
}
