import { AsyncResult, err, ok } from '@whr/core_utils/result'
import { Action } from '../actions'
import { Validator } from '../validator'
import BaseGuard from './base_guard'

export default class NotGuard extends BaseGuard implements Validator {
  constructor (private readonly _validator: Validator) {
    super()

    this.on = _validator.on.bind(_validator)
  }

  get name (): string {
    return `not_${this._validator.name}`
  }

  async validate (action?: Action): AsyncResult<boolean, Error> {
    const result = await this._validator.validate(action)

    if (result.isErr()) return err(result.error)

    return ok(!result.value)
  }
}
