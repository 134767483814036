import { TOptions } from 'i18next'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export type TranslationOptions = Pick<TOptions, 'count' | 'ordinal' | 'context' | 'ns'> & Record<string, unknown>

interface HookResult {
  translate: (message: string, options?: TranslationOptions) => string
}

export default function useTranslate (): HookResult {
  const { t } = useTranslation()

  return {
    translate: useCallback((message, options?: TranslationOptions) => t(message, options), [t])
  }
}
