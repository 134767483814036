import AuthenticationError from './authentication_error'

enum InvalidCredentialsErrorCodes {
  INVALID_CREDENTIALS = 'auth/invalid-credentials'
}

export default class InvalidCredentialsError extends AuthenticationError<InvalidCredentialsErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, InvalidCredentialsErrorCodes.INVALID_CREDENTIALS)
  }
}
