import { MissingDependencyError } from '@whr/core_utils/missingDependencyError'
import { AsyncResult, err, ok } from '@whr/core_utils/result'
import { useCallback } from 'react'
import { AuthenticationErrors, getAuthenticationProvider } from '~/service_providers/authentication/authentication_provider'

export type UpgradeAnonymousUserWithGoogle = () => AsyncResult<void, AuthenticationErrors | MissingDependencyError>

interface UseUpgradeAnonymousUserWithGoogleHookResult {
  upgradeAnonymousUserWithGoogle: UpgradeAnonymousUserWithGoogle
}

export function useUpgradeAnonymousUserWithGoogle (): UseUpgradeAnonymousUserWithGoogleHookResult {
  const upgradeAnonymousUserWithGoogle = useCallback<UpgradeAnonymousUserWithGoogle>(async () => {
    const authProvider = getAuthenticationProvider()

    if (authProvider.isErr()) {
      return err(authProvider.error)
    }

    const upgradeResult = await authProvider.value.upgradeAnonymousUserWithGoogle()

    if (upgradeResult.isErr()) {
      return err(upgradeResult.error)
    }

    return ok()
  }, [])

  return { upgradeAnonymousUserWithGoogle }
}
