import AuthenticationError from './authentication_error'

enum UserDisabledErrorCodes {
  USER_DISABLED = 'auth/user-disabled'
}

export default class UserDisabledError extends AuthenticationError<UserDisabledErrorCodes> {
  constructor (message: string, originalError: any) {
    super(message, originalError, UserDisabledErrorCodes.USER_DISABLED)
  }
}
