import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

interface HookResult {
  loading: boolean
  error?: Error
  isAuthorized: boolean
}

type Subjects = 'SalesPeople' | 'ProjectAssignment' | 'ProjectAssignment::SaleRate' | 'ProjectAssignment::WorkingHours' | 'ProjectAssignment::SaleCommission'

export const requiredPermissions: SubjectActionsCollection<Subjects> = [
  ['SalesPeople', ['read']],
  ['ProjectAssignment', ['read']],
  ['ProjectAssignment::SaleRate', ['read']],
  ['ProjectAssignment::WorkingHours', ['read']],
  ['ProjectAssignment::SaleCommission', ['read']]
]

export default function useCanReadSalesPeople (): HookResult {
  const { loading, error, authorizations } = useVerifyAuthorizations(requiredPermissions)

  return {
    isAuthorized: authorizations?.isFullyAuthorized ?? false,
    loading,
    error
  }
}
