import { IS_LOCAL } from '~/constants'
import { setAuthenticationProvider } from '../service_providers/authentication/authentication_provider'
import FirebaseAuthenticationProvider from '../service_providers/firebase/authentication_provider'

let dependenciesInjected = false

export default function setDependencies (): void {
  if (dependenciesInjected) {
    // eslint-disable-next-line no-console
    if (IS_LOCAL) console.warn('Avoiding re-injection of dependencies due to hot-reload.')

    return
  }

  dependenciesInjected = true

  setAuthenticationProvider(new FirebaseAuthenticationProvider())
}
