import { SubjectActionsCollection, useVerifyAuthorizations } from '~/authorization/hooks/useVerifyAuthorizations'

interface HookResult {
  loading: boolean
  error?: Error
  isAuthorized: boolean
}

export const requiredPermissions: SubjectActionsCollection<'Project' | 'Client' | 'Billing::Invoice'> = [
  ['Project', ['read']],
  ['Client', ['read']],
  ['Billing::Invoice', [{ action: 'read', field: 'startDate' }, { action: 'read', field: 'endDate' }]]
]

export default function useCompanyProfitAndLossAuthorization (): HookResult {
  const { loading, error, authorizations } = useVerifyAuthorizations(requiredPermissions)

  return {
    loading,
    error,
    isAuthorized: authorizations?.isFullyAuthorized ?? false
  }
}
